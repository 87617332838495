<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_430_6876)">
            <path d="M13.7555 1.28262C14.1066 1.28262 14.3883 1.00037 14.3883 0.649284C14.3883 0.321304 14.1066 0.0400391 13.7555 0.0400391C13.426 0.0400391 13.1453 0.321304 13.1453 0.649284C13.1453 1.00037 13.426 1.28262 13.7555 1.28262Z" fill="#1AC632"/>
            <path d="M20.8396 9.28246C20.5599 9.28246 20.316 9.42063 20.1522 9.62814C20.074 8.05365 18.7705 6.77271 17.1576 6.77271C16.8282 6.77271 16.512 6.82876 16.2155 6.92957C15.6859 6.15019 14.8175 5.62503 13.8301 5.56209C13.5189 3.9335 12.0914 2.69092 10.3527 2.69092C8.40595 2.69092 6.81031 4.28557 6.81031 6.23231C6.81031 6.66797 6.89243 7.08249 7.03404 7.468C6.47594 7.66518 6.05896 8.20264 6.05896 8.83696C6.05896 9.63404 6.7159 10.2684 7.49085 10.2684C8.07944 10.2684 8.58739 9.92268 8.81605 9.42162C9.2822 9.64683 9.80244 9.77566 10.3527 9.77566C10.4653 9.77566 10.5754 9.76828 10.6856 9.75845C10.7549 9.94629 10.8415 10.1248 10.9437 10.2929C9.99274 10.4714 9.20746 11.1279 8.84948 12.0032C8.54855 11.3285 7.87194 10.854 7.09157 10.854C6.03585 10.854 5.16992 11.7219 5.16992 12.7771C5.16992 13.8324 6.03585 14.7022 7.09157 14.7022C7.78589 14.7022 8.39956 14.3241 8.73688 13.7655C9.05601 14.956 10.155 15.8514 11.4561 15.8514C11.5515 15.8514 11.6459 15.8465 11.7388 15.8371C11.7777 16.1213 11.8637 16.3898 11.9921 16.6342C11.4851 16.9823 11.1498 17.565 11.1498 18.22C11.1498 19.2762 12.0191 20.1441 13.0739 20.1441C14.1286 20.1441 14.9965 19.2767 14.9965 18.22C14.9965 18.0858 14.9842 17.9564 14.9577 17.8296C15.6043 17.6063 16.1304 17.1176 16.4068 16.4896C16.6512 16.6898 16.957 16.8122 17.2968 16.8122C18.0944 16.8122 18.7513 16.1558 18.7513 15.3582C18.7513 14.5606 18.0944 13.9268 17.2968 13.9268C16.9137 13.9268 16.5705 14.0753 16.3148 14.3162C15.9082 13.5727 15.1317 13.0643 14.2452 13.0392C14.2427 12.5932 14.135 12.1738 13.9486 11.7986C14.2417 11.7696 14.5215 11.6968 14.7861 11.5911C15.3304 12.3115 16.1884 12.7762 17.1566 12.7762C18.5364 12.7762 19.6895 11.8556 20.044 10.5914C20.1896 10.884 20.4886 11.0876 20.8387 11.0876C21.3319 11.0876 21.7306 10.6883 21.7306 10.1961C21.7306 9.70387 21.3319 9.28049 20.8387 9.28049L20.8396 9.28246Z" fill="#1AC632"/>
            <path d="M8.59389 15.8282C8.10069 15.8282 7.7019 16.227 7.7019 16.7197C7.7019 17.2124 8.10069 17.6112 8.59389 17.6112C9.08709 17.6112 9.48489 17.212 9.48489 16.7197C9.48489 16.2275 9.0861 15.8282 8.59389 15.8282Z" fill="#1AC632"/>
            <path d="M12.2292 20.3796C11.5497 20.3796 11.0098 20.9432 11.0098 21.6001C11.0098 22.257 11.5497 22.8432 12.2292 22.8432C12.9088 22.8432 13.4723 22.2806 13.4723 21.6001C13.4723 20.9195 12.9098 20.3796 12.2292 20.3796Z" fill="#1AC632"/>
            <path d="M9.31958 19.7227C9.31958 19.3696 9.60183 19.0903 9.9539 19.0903C10.3065 19.0903 10.5882 19.3706 10.5882 19.7227C10.5882 20.0748 10.3065 20.357 9.9539 20.357C9.60134 20.357 9.31958 20.0757 9.31958 19.7227Z" fill="#1AC632"/>
            <path d="M16.1245 18.6203C16.1245 18.2913 16.4053 18.0101 16.7574 18.0101C17.1084 18.0101 17.3917 18.2913 17.3917 18.6203C17.3917 18.9719 17.1094 19.2531 16.7574 19.2531C16.4053 19.2531 16.1245 18.9719 16.1245 18.6203Z" fill="#1AC632"/>
            <path d="M3.97159 6.51556C3.29105 6.51556 2.72852 7.054 2.72852 7.73454C2.72852 8.41509 3.29105 8.97762 3.97159 8.97762C4.65213 8.97762 5.19106 8.41509 5.19106 7.73454C5.19106 7.054 4.62853 6.51556 3.97159 6.51556Z" fill="#1AC632"/>
            <path d="M18.2119 5.01332C18.2119 5.50553 17.7905 5.90334 17.2973 5.90334C16.8055 5.90334 16.4053 5.50603 16.4053 5.01332C16.4053 4.52061 16.8041 4.12183 17.2973 4.12183C17.7905 4.12183 18.2119 4.5211 18.2119 5.01332Z" fill="#1AC632"/>
            <path d="M10.4702 23.5464C10.4702 23.2651 10.6812 23.0541 10.9393 23.0541C11.2211 23.0541 11.4315 23.2651 11.4315 23.5464C11.4315 23.805 11.2211 24.04 10.9393 24.04C10.6817 24.04 10.4702 23.805 10.4702 23.5464Z" fill="#1AC632"/>
        </g>
        <defs>
            <clipPath id="clip0_430_6876">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default{
        name: 'EmissionSVG'
    }
</script>