<template>
    <li class="cdchampinion__list-entry">
        <div class="cdchampinion">
            <div class="cdchampinion__type">
                <BadgeSVG ref="badge" class="cdchampinion__element cdchampinion__badge" :class="showBadge?'':'cdchampinion__badge--invisible'"  :rank="rank"/>
                <slot class="cdchampinion__element" name="icon"></slot>
                <span class="cdchampinion__element cdchampinion__name" >{{ name }}</span>
            </div>
            <div>
                <span class="cdchampinion__savings-bar cdchampinion__element">
                    <span ref="percentage" class="cdchampinion__bar-percentage"></span>
                </span>
                <span class="cdchampinion__element cdchampinion__percentage">{{ percent.toFixed(1) }} %</span>
            </div>
        </div>
    </li>
</template>

<script>
import BadgeSVG from '.././app1_components/svg/components/BadgeSVG.vue';

export default({
    name: "HiddenChampinion",
    components: { BadgeSVG },
    data() {
        return{
            rank: 0
        }
    },
    props: {
        name: String,
        variableName: String,
        percent: {
            default: 80,
            Type: Number
        },
        uniqueRank: {
          default: 4,
          type: Number
        },
        showBadge: false
    },
    mounted() {
        this.updatePercentage(this.percent);
    },
    watch: {

        percent(newVal){
            setTimeout(() => {
                const roomBadge = document.getElementById("cdproduct-badge--" + this.variableName);
                if (newVal <= 0){
                    roomBadge.classList.remove('cdproduct-badge--visible');
                    roomBadge.classList.add('cdproduct-badge--invisible');

                    this.$refs.badge.$el.classList.remove('cdchampinion__badge--visible');
                    this.$refs.badge.$el.classList.add('cdchampinion__badge--invisible');
                }
            }, 400);
            this.updatePercentage(newVal);
        },
        uniqueRank(newVal){
            this.rank = Math.trunc(newVal);
            setTimeout(() => {
                const roomBadge = document.getElementById("cdproduct-badge--" + this.variableName);

                if (this.rank <= 3 && this.percent > 0){
                    roomBadge.classList.remove('cdproduct-badge--invisible');
                    roomBadge.classList.add('cdproduct-badge--visible');

                    this.$refs.badge.$el.classList.remove('cdchampinion__badge--invisible');
                    this.$refs.badge.$el.classList.add('cdchampinion__badge--visible');
                }
                else {
                    roomBadge.classList.add('cdproduct-badge--invisible');
                    roomBadge.classList.remove('cdproduct-badge--visible');

                    this.$refs.badge.$el.classList.remove('cdchampinion__badge--visible');
                    this.$refs.badge.$el.classList.add('cdchampinion__badge--invisible');
                }
            }, 200);
        }
    },
    methods: {
        updatePercentage(percent){
            if (String(percent) >= 0){
                this.$refs.percentage.style.width = String(percent) + "%";
            }
            else {
                this.$refs.percentage.style.width = "0%";
            }
            
        }
    }
})

</script>

<style scoped lang="scss">


    .cdgame{


    .cdchampinion__list-entry{
        transition: box-shadow 0.3s ease-in-out;
        background: var(--cd-text-color-light);
        border-radius: 0.25rem;
        border: 1px solid #EBEBEB;
        overflow: hidden;
    }

    .cdchampinion__list-entry:not(:last-child){
        margin: 0.3125rem 0;
    }
    .cdchampinion {
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        //cursor: pointer;
        max-width: 100%;
    }

    .cdchampinion__type{
        min-width: 160px;
    }

    .cdchampinion__name{
        font-size: 0.875rem;
    }

    .cdchampinion > div{
        display: flex;
        align-items: center;
    }

    .cdchampinion__savings-bar{
        display: flex;
        height: 0.25rem;
        background-color: var(--cd-background-middle-grey) ;
        width: 4.5vw;
        max-width: 5rem;
    }

    .cdchampinion__bar-percentage {
        background-color: var(--cd-color-green);
        height: 100%;
        transition: width 0.3s ease-in-out;
    }

    .cdchampinion__element{
        margin: 0 0.5rem;
    }
    .cdchampinion__percentage {
        font-size: 0.875rem;
        min-width: 42px;
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .cdchampinion__badge{
        opacity: 100;
        /* transition: all 0.3s */
    }

    .cdchampinion__badge--invisible{
        opacity: 0;
    }

    @media screen and (max-width: 1100px){
        .cdchampinion__savings-bar{
            min-width: 5vw;
            margin: 0;
        }    
    }

    @media screen and (max-width: 768px) {
        .cdchampinion__savings-bar{
            min-width: 3vw;
            margin: 0;
        } 
    }

    @media screen and (max-width: 650px) {
        .cdchampinion__savings-bar{
            min-width: 5vw;
            width: 16vw;
            margin: 0 0rem;
        }    
    }

    @media screen and (max-width: 370px) {
        .cdchampinion__savings-bar{
            min-width: 5vw;
            width: 8vw;
            margin: 0 0.25rem;
        }    
    }

    }


</style>