<template>
    <div class="cdrange">
        <input class="cdrange__input" ref="input" type="range" min="0" :max="values.length - 1" :value=defaultOpt @input="OnSliderInput">
        <div ref="pulse" class="cdrange__pulse" v-show="pulse === true"></div>
        <div class="cdrange__list" id="number">
          <span v-for="value in values" class="cdrange__opt" :key="value">{{ value }}</span>
        </div> 
    </div>
  </template>
  
<script>
  export default {
    name: 'SliderInput',
    data() {
        return {
          sliderVal: 0,
          sliderTrackRange: null,
          wasClicked: false
        }
    },
    props: {
      values: Array,
      defaultOpt: {
        default: 0,
          type: Number
        },
      pulse: {
        default: false,
        type: Boolean
      },
      isActive: Boolean
    },
    watch: {
        isActive(newVal) {
            if (newVal === false) {
              this.$refs.input.value = this.defaultOpt;
              this.sliderVal = this.$refs.input.value;
              this.sliderTrackRange = ((this.defaultOpt / (this.values.length-1))*100).toFixed(3);
              this.AdjustSliderTrack(this.sliderTrackRange);
              this.$emit('updateValue');
            }
        },
        sliderVal() {
          this.$emit('updateValue');
        },
        '$i18n.locale': function(newVal, oldVal) {
              setTimeout(()=> {
                this.$refs.input.value = this.sliderVal;
          }
        ,10);
        }
    },
    mounted() {
      this.$refs.input.value = this.defaultOpt;
      this.sliderVal = this.$refs.input.value;
      this.sliderTrackRange = ((this.defaultOpt / (this.values.length-1))*100).toFixed(3);
      this.AdjustSliderTrack(this.sliderTrackRange);
      this.$emit('updateValue');
  },
    methods: {
      OnSliderInput(event) {
        this.$refs.pulse.style.display = "none";
        this.sliderVal = event.target.value;
        this.sliderTrackRange = ((event.target.value / (this.values.length-1))*100).toFixed(3);
        this.AdjustSliderTrack(this.sliderTrackRange);
      },
      AdjustSliderTrack(percentage){
        this.$refs.input.style.background = "linear-gradient(to right, var(--cd-color-danfoss-red) 0%, var(--cd-color-danfoss-red) " + percentage + "%, #BFBEBE " + percentage +"%, #BFBEBE 100%)"
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.cdgame{

.cdrange{
  padding: 0 0.25rem;
}


@media (pointer: fine) {
  .cdrange__input::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: all;
}
}

.cdrange input[type="range"]{
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  background: linear-gradient(to right, var(--cd-color-danfoss-red) 0%, var(--cd-color-danfoss-red) 50%, #BFBEBE 50%, #BFBEBE 100%);
  cursor: pointer;
  margin-top: 0.5rem;
  margin-left: 0;
  margin-bottom: 2px;
  width: 100%;
  height: 0.25rem;
  border-radius: 2px;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.cdrange input[type="range"]::-webkit-slider-runnable-track {
  /* background: lightgrey; */
  /* background: linear-gradient(to right, var(--cd-color-danfoss-red) 0%, var(--cd-color-danfoss-red) 50%, lightgrey 50%, lightgrey 100%); */
  height: 0.25rem;
  border-radius: 2px;
}

/******** Firefox ********/
.cdrange input[type="range"]::-moz-range-track {
  /* background: lightgrey; */
  /* background: linear-gradient(right, red, red, lightgrey, lightgrey); */
  height: 0.25rem;
  border-radius: 2px;
}


/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.cdrange input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
   appearance: none;
   margin-top: -0.375rem; /* Centers thumb on the track */
   background-color: var(--cd-color-danfoss-red);
   transform: scale(1);
   height: 1rem;
   width: 1rem;  
   border-radius: 1rem;
   cursor: pointer;
   /* margin: 2rem; */
   box-shadow: 0px 1px 3px 0px #00000033;
   box-shadow: 0px 1px 1px 0px #00000024;
   transition: all 0.3s ease-in-out;
}

.cdrange__pulse {
  position: relative;
  line-height: unset!important;;
  height: 1rem;
  width: 1rem;  
  background-color: var(--cd-color-danfoss-red);
  border-radius: 1em;
  box-shadow: 0px 1px 3px 0px #00000033;
  box-shadow: 0px 1px 1px 0px #00000024;
  margin-top: -0.75rem;
  margin-bottom: calc(-2px - 0.25rem);;
  animation: pulse 1.5s infinite;
  pointer-events: none;
}

.cdrange__pulse-invisible {
  opacity: 0;
}

@keyframes pulse {
	0% {
		transform: scale(1);
  }
  50% {
		transform: scale(1.3);
	}
  55% {
		transform: scale(1.3);
	}
  100% {
		transform: scale(1);
	}
  }

/***** Thumb Styles *****/
/***** Firefox *****/
.cdrange input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 50%; 
    background-color: var(--cd-color-danfoss-red);
    height: 1rem;
    width: 1rem; 
    cursor: pointer;
    box-shadow: 0px 1px 3px 0px #00000033;
    box-shadow: 0px 1px 1px 0px #00000024;
    animation: pulse 1.5s infinite;
}

.cdrange__list{
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    margin-top: 0.75rem;
}

.cdrange__opt{
    font-size: 0.875rem;
    color: #303030;
    width: 1rem;
    padding: 0;
    display: flex;
}

}


</style>
  