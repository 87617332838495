<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_430_8817)">
            <path d="M6 13L13 0V11H18L11 24V13H6Z" fill="#1AC632"/>
        </g>
        <defs>
            <clipPath id="clip0_430_8817">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default ({
    name: 'EnergySVG'
})
</script>