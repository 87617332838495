<template>
    <nav id="game-header" class="fixed-top navbar justify-content-center">
        <div class="row container-lg">
            <div class="col">
                <img class="cdgame__header-logo" src="../.././assets/logo/Danfoss-Logo.png" alt="Danfoss">
            </div>
            <div class="col d-none d-md-flex justify-content-center">
                <i18n path="start-screen.heading.text" tag="span" class="text-center">
                    <template v-slot:0><b>{{ $t("start-screen.heading.0") }}</b></template>
                </i18n>
            </div>
            <div class="col d-flex justify-content-end">
                <button class="btn" @click="location.reload()">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.364 8.05026L17.6569 7.34315C14.5327 4.21896 9.46734 4.21896 6.34315 7.34315C3.21895 10.4673 3.21895 15.5327 6.34315 18.6569C9.46734 21.7811 14.5327 21.7811 17.6569 18.6569C19.4737 16.84 20.234 14.3668 19.9377 12.0005M18.364 8.05026H14.1213M18.364 8.05026V3.80762" stroke="#ffffff" stroke-width="1.9200000000000004" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <span>{{ $t("restart") }}</span>
                </button> 
                <div class="dropdown">
                    <button class="btn language-switcher dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                        <svg fill="#ffffff" width="20" height="20" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="12"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.048"></g><g id="SVGRepo_iconCarrier"> <path d="M128,28.00037a100,100,0,1,0,100,100A100.113,100.113,0,0,0,128,28.00037Zm91.90771,96H171.94043c-.93994-34.249-13.937-65.64722-36.18994-87.67041A92.1307,92.1307,0,0,1,219.90771,124.00037ZM128,216.03064c-21.71777-20.37207-34.93213-51.28711-35.93164-84.03027h71.86328C162.93213,164.74353,149.71777,195.65857,128,216.03064ZM92.06836,124.00037C93.06787,91.25476,106.27979,60.34143,128,39.97009c21.72021,20.37134,34.93213,51.28467,35.93164,84.03028ZM120.24951,36.33c-22.25293,22.02319-35.25,53.42138-36.18994,87.67041H36.09229A92.1307,92.1307,0,0,1,120.24951,36.33ZM36.09229,132.00037H84.05957c.93994,34.24756,13.937,65.64648,36.18994,87.67041A92.13091,92.13091,0,0,1,36.09229,132.00037Zm99.6582,87.67041c22.25293-22.02393,35.25-53.42285,36.18994-87.67041h47.96728A92.13091,92.13091,0,0,1,135.75049,219.67078Z"></path> </g></svg>
                        <span>{{ $t("current-language")}}</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <span class="dropdown-item" @click="switchLanguage('de')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 5 3">
                                    <rect id="black_stripe" width="5" height="1" y="0" x="0" fill="#000"/>
                                    <rect id="red_stripe" width="5" height="1" y="1" x="0" fill="#D00"/>
                                    <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
                                </svg>
                            <span>Deutsch</span>
                            </span>
                        </li>
                        <li>
                            <span class="dropdown-item" @click="switchLanguage('en')">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="18" height="18" style="transform: scaleY(1.2);">
                                    <clipPath id="s">
                                        <path d="M0,0 v30 h60 v-30 z"/>
                                    </clipPath>
                                    <clipPath id="t">
                                        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
                                    </clipPath>
                                    <g clip-path="url(#s)">
                                        <path d="M0,0 v30 h60 v-30 z" fill="#012169"/>
                                        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
                                        <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
                                        <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
                                        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6"/>
                                    </g>
                                </svg>
                            <span>English</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    export default({
        name: "AppHeader",
        components: {
          
        },
        data() {
            return {
            isActive: 0,
            value: null,
            }
        },
        methods: {
            switchLanguage(lang){
                this.$i18n.locale = lang;
            }
        },
        computed: {
            location: () => location,
            //window: () => window,
        },
        watch: {
           
        }
    })
</script>

<style scoped lang="scss">


#game-header{
    background-color: var(--cd-color-danfoss-red);
    color: var(--cd-text-color-light);
    z-index: 10;

    > div {
        padding: 0;
    }

    button > span, li span {
        font-size: 14px;
    }

    button > span {
        display: contents;
    }
}

.btn{
    color: var(--cd-text-color-light);
    display: grid;
    grid-auto-flow: column;
    gap: 0.325rem;
    align-items: center;
}

.cdgame__header-logo{
    height: 60px;
    line-height: 24px;
    margin-left: -1px;
    transition-duration: .2s;
    transition-property: height;
    transition-timing-function: ease-in-out;
}

span.dropdown-item{
    cursor: pointer;
    &:active{
        background: var(--cd-background-middle-grey) !important;
        color: unset;
    }

    &:hover{
        background: var(--cd-background-light-grey) !important;
    }
}

.dropdown-menu {
    min-width: 0;
    width: fit-content;
}


.dropdown-item{
    display: grid;
    grid-auto-flow: column;
    gap: 0.325rem;
    align-items: center;
    width: unset;
}

</style>