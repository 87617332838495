<template>
    <InputToggle ref="toggler" @toggleInput="isActive = $refs.toggler.isActive" :canBeActivated=true :shouldPulse=this.shouldPulse>
        <template #knob>
            <PhotovoltaicSVG />
        </template>
        <template #input="slotProps">
            <SliderInput ref="input" @updateValue="value = $refs.input.sliderVal" :values="['0', '10' , '20', '30']" :defaultOpt="0" :isActive=slotProps.isActive :pulse=sliderPulse />
        </template>
    </InputToggle>
</template>

<script>
    import InputToggle from '.././InputToggle.vue'
    import SliderInput from '.././SliderInput.vue'
    import PhotovoltaicSVG from '.././svg/icons/PhotovoltaicSVG.vue'

    export default({
        name: "PhotovoltaicToggleInput",
        components: {
            InputToggle,
            SliderInput,
            PhotovoltaicSVG
        },
        data() {
            return {
            isActive: 0,
            value: null,
            sliderPulse: true,
            shouldPulse: true
            }
        },
        computed: {
            greenEnergyActivated() {
                return this.$store.state.energySources.greenEnergy.wasActivated;
            }
        },
        watch: {
            greenEnergyActivated(newVal) {
                if (newVal === true && this.$store.state.energySources.photovoltaic.value === 0){
                    this.sliderPulse = false;
                }
            },
            isActive(newVal) {
                if (newVal === true && this.$store.state.activated === false){
                    this.$store.commit('setActivated',  { value: true});
                    this.$store.commit('setActivatedStatus',  { input: 'photovoltaic', activated: true });
                }
                this.$store.commit('setInputStatus',  { input: 'photovoltaic', activated: parseInt(newVal) });
            },
            value(newVal){
                this.$store.commit('setInputValue',  { input: 'photovoltaic', value: parseInt(newVal) });
            }
        }
    })
</script>