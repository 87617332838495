import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    internetConnection: false,
    activated: false,
    energySources:{
      photovoltaic: {
        wasActivated: false,
        activated: false, 
        value: 0,
      },
      greenEnergy: {
        wasActivated: false,
        activated: false, 
        value: 0,
      }
    },
    driveSystem: {
      activated: false,
      isVisible: false,
      motorEfficiency: {
        activated: false, 
        value: 0
      },
      control: {
        activated: false, 
        value: 0
      },
      regenerativeEnergy: {
        activated: false, 
        inputPossible: false,
        value: 0
      }
    },
    energySavingsTotal: 0,
    emissionSavingsTotal: 0,
    pump: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 2.15,
        2: 3.98,
        3: 5.44,
        4: 7.07,
        5: 7.56
      },
      controlVal:{
        0: 0,
        1: 51.48,
        2: 16.56,
      },
      reEnergyVal:{
        0: 0,
        1: -2.1,
      }
    },
    fan: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 2.44,
        2: 4.57,
        3: 6.21,
        4: 8.00,
        5: 8.60
      },
      controlVal:{
        0: 0,
        1: 45.53,
        2: 16.52,
      },
      reEnergyVal:{
        0: 0,
        1: -2.1,
      }
    },
    compressor: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 2.43,
        2: 4.46,
        3: 6.10,
        4: 7.98,
        5: 8.55
      },
      controlVal:{
        0: 0,
        1: 15.02,
        2: -7.33,
      },
      reEnergyVal:{
        0: 0,
        1: -2.1,
      }
    },
    conveyor: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 4.24,
        2: 8.63,
        3: 11.14,
        4: 14.27,
        5: 15.04
      },
      controlVal:{
        0: 0,
        1: 4.36,
        2: 0
      },
      reEnergyVal:{
        0: 0,
        1: -2.1,
      }
    },
    lift: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 3.37,
        2: 6.61,
        3: 8.65,
        4: 11.30,
        5: 12.00
      },
      controlVal:{
        0: 0,
        1: 0,
        2: 0
      },
      reEnergyVal:{
        0: 0,
        1: 37.03,
      }
    },
    elevator: {
      rank: null,
      energyConsumption: 100,
      energySavings: 0,
      motorEfficiencyVal: {
        0: 0, 
        1: 3.37,
        2: 6.61,
        3: 8.65,
        4: 11.30,
        5: 12.00
      },
      controlVal:{
        0: 0,
        1: 5.0,
        2: 0
      },
      reEnergyVal:{
        0: 0,
        1: -1.07,
      }
    },
    ranking: {
      activated: false,
      running: false
    },
    badges: {
      activated: false,
    },
    feedback: {
      isVisible: false,
      activated: false
    }
  },
  mutations: {
    setConnection(state, {value}){
      state.internetConnection = value;
    },
    setActivated (state, {value}){
      state.activated = value;
    },
    setRank(state, {product, rank}){
      if (product == 'pump'){
        state.pump.rank = rank;
      }
      else if (product == 'lift'){
        state.lift.rank = rank;
      }
      else if (product == 'fan'){
        state.fan.rank = rank;
      }
      else if (product == 'elevator'){
        state.elevator.rank = rank;
      }
      else if (product == 'compressor'){
        state.compressor.rank = rank;
      }
      else if (product == 'conveyor'){
        state.conveyor.rank = rank;
      }
    },
    setTotalEnergySavings(state, {value}){
      state.energySavingsTotal = value;
    },
    setTotalEmissionSavings(state, {value}){
      state.emissionSavingsTotal = value;
    },
    setEnergyConsumptionAndSavings(state, {product, consumption, savings}){
      if (product == 'pump'){
        state.pump.energyConsumption = consumption;
        state.pump.energySavings = savings;
      }
      else if (product == 'lift'){
        state.lift.energyConsumption = consumption;
        state.lift.energySavings = savings;
      }
      else if (product == 'fan'){
        state.fan.energyConsumption = consumption;
        state.fan.energySavings = savings;
      }
      else if (product == 'elevator'){
        state.elevator.energyConsumption = consumption;
        state.elevator.energySavings =savings;
      }
      else if (product == 'compressor'){
        state.compressor.energyConsumption = consumption;
        state.compressor.energySavings = savings;
      }
      else if (product == 'conveyor'){
        state.conveyor.energyConsumption = consumption;
        state.conveyor.energySavings = savings;
      }
    },
    setInputStatus(state,  { input, activated }){

      if (input == 'photovoltaic'){
        state.energySources.photovoltaic.activated = activated;
      }
      else if (input == 'green-energy'){
        state.energySources.greenEnergy.activated = activated;
      }
      else if (input == 'control'){
        state.driveSystem.control.activated = activated;
      }
      else if (input == 'motor-efficiency'){
        state.driveSystem.motorEfficiency.activated = activated;
      }
      else if (input == 'regenerative-energy'){
        state.driveSystem.regenerativeEnergy.activated = activated;
      }
    },
    setActivatedStatus(state,  { input, activated }){
      if (input == 'photovoltaic'){
        state.energySources.photovoltaic.wasActivated = activated;
      }
      else if (input == 'green-energy'){
        state.energySources.greenEnergy.wasActivated = activated;
      }
    },
    setInputValue(state,  { input, value }){
      if (input == 'photovoltaic'){
        state.energySources.photovoltaic.value = value;
      }
      else if (input == 'green-energy'){
        state.energySources.greenEnergy.value = value;
      }
      else if (input == 'control'){
        state.driveSystem.control.value = value;
      }
      else if (input == 'motor-efficiency'){
        state.driveSystem.motorEfficiency.value = value;
      }
      else if (input == 'regenerative-energy'){
        state.driveSystem.regenerativeEnergy.value = value;
      }
    },

    activateOption(state,  { kind, activated }){
      if (kind == 'drive-system'){
        state.driveSystem.activated = activated;
      }
      else if (kind == 'ranking-running'){
        state.ranking.running = activated;
      }
      else if (kind == 'room'){
        state.driveSystem.isVisible = activated;
      }
      else if (kind == 'ranking'){
        state.ranking.activated = activated;
      }
      else if (kind == 'badges'){
        state.badges.activated = activated;
      }
      else if (kind == 'feedback'){
        state.feedback.activated = activated;
      }
      else if (kind == 'feedback-button'){
        state.feedback.isVisible = activated;
      }
      else if (kind == 'regenerative-energy'){
        state.driveSystem.regenerativeEnergy.inputPossible = activated;
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
