<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 19L19.0001 13V18H21.0001L18.0001 24L18 19H16Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03001 17.9296C3.32001 15.1996 3.32001 10.7796 6.05001 8.04956C9.04001 5.05956 17.19 4.21956 19.43 4.03956C19.73 4.00956 19.98 4.25956 19.96 4.56956C19.8581 5.83748 19.5448 8.99898 18.7497 12.0464C18.5041 12.0158 18.2539 12 18 12C14.6863 12 12 14.6863 12 18C12 18.6548 12.1049 19.2851 12.2988 19.875C10.8579 20.1435 9.34828 19.9574 8.00001 19.3196C8.68001 15.6096 10.62 12.3396 13.39 9.99956C10.12 11.6896 7.50001 14.5296 6.03001 17.9296Z"/>
    </svg>
</template>

<script>
export default {
    name: 'GreenEnergySVG',
}
</script>
    