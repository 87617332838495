<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g v-if="rank == 1">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98519 15.3862L3.04883 20.0004L6.54883 19.5004L8.04883 23.0004L10.5628 17.9725C10.0887 17.4146 9.29442 17.2332 8.619 17.5458C7.58639 18.0238 6.392 17.3342 6.28961 16.2009C6.26224 15.898 6.15345 15.619 5.98519 15.3862ZM13.4679 17.9368L15.9997 23.0004L17.4997 19.5004L20.9997 20.0004L18.0407 15.3506C17.8575 15.5904 17.7387 15.8824 17.7099 16.2009C17.6075 17.3342 16.4131 18.0238 15.3805 17.5458C14.7197 17.24 13.9451 17.407 13.4679 17.9368Z" fill="#E2000F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2229 0.73581C12.6271 -0.109853 11.3734 -0.109853 10.7776 0.73581C10.3615 1.32652 9.58257 1.53523 8.92684 1.23173C7.9881 0.797225 6.9023 1.42411 6.80922 2.45434C6.7442 3.17397 6.17397 3.7442 5.45434 3.80922C4.42411 3.9023 3.79722 4.9881 4.23173 5.92684C4.53523 6.58257 4.32652 7.36151 3.73581 7.77763C2.89015 8.37336 2.89015 9.62713 3.73581 10.2229C4.32652 10.639 4.53523 11.4179 4.23173 12.0737C3.79722 13.0124 4.42411 14.0982 5.45434 14.1913C6.17397 14.2563 6.7442 14.8265 6.80922 15.5462C6.9023 16.5764 7.9881 17.2033 8.92684 16.7688C9.58257 16.4653 10.3615 16.674 10.7776 17.2647C11.3734 18.1103 12.6271 18.1103 13.2229 17.2647C13.639 16.674 14.4179 16.4653 15.0737 16.7688C16.0124 17.2033 17.0982 16.5764 17.1913 15.5462C17.2563 14.8265 17.8265 14.2563 18.5462 14.1913C19.5764 14.0982 20.2033 13.0124 19.7688 12.0737C19.4653 11.4179 19.674 10.639 20.2647 10.2229C21.1103 9.62713 21.1103 8.37336 20.2647 7.77763C19.674 7.36151 19.4653 6.58257 19.7688 5.92684C20.2033 4.9881 19.5764 3.9023 18.5462 3.80922C17.8265 3.7442 17.2563 3.17397 17.1913 2.45434C17.0982 1.42411 16.0124 0.797225 15.0737 1.23173C14.4179 1.53523 13.639 1.32652 13.2229 0.73581ZM12.0002 16.0002C15.8662 16.0002 19.0002 12.8662 19.0002 9.00025C19.0002 5.13425 15.8662 2.00025 12.0002 2.00025C8.13425 2.00025 5.00025 5.13425 5.00025 9.00025C5.00025 12.8662 8.13425 16.0002 12.0002 16.0002Z" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" stroke="black" stroke-opacity="0.1"/>
            <circle cx="12" cy="9" r="6.5" stroke="white"/>
            <path d="M11.5866 6.85H11.5666L10.3266 7.44L10.0766 6.3L11.7966 5.5H13.0566V12H11.5866V6.85Z" fill="white"/>
        </g>
        <g v-if="rank == 2">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98519 15.3862L3.04883 20.0004L6.54883 19.5004L8.04883 23.0004L10.5628 17.9725C10.0887 17.4146 9.29442 17.2332 8.619 17.5458C7.58639 18.0238 6.392 17.3342 6.28961 16.2009C6.26224 15.898 6.15345 15.619 5.98519 15.3862ZM13.4679 17.9368L15.9997 23.0004L17.4997 19.5004L20.9997 20.0004L18.0407 15.3506C17.8575 15.5904 17.7387 15.8824 17.7099 16.2009C17.6075 17.3342 16.4131 18.0238 15.3805 17.5458C14.7197 17.24 13.9451 17.407 13.4679 17.9368Z" fill="#E2000F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2229 0.73581C12.6271 -0.109853 11.3734 -0.109853 10.7776 0.73581C10.3615 1.32652 9.58257 1.53523 8.92684 1.23173C7.9881 0.797225 6.9023 1.42411 6.80922 2.45434C6.7442 3.17397 6.17397 3.7442 5.45434 3.80922C4.42411 3.9023 3.79722 4.9881 4.23173 5.92684C4.53523 6.58257 4.32652 7.36151 3.73581 7.77763C2.89015 8.37336 2.89015 9.62713 3.73581 10.2229C4.32652 10.639 4.53523 11.4179 4.23173 12.0737C3.79722 13.0124 4.42411 14.0982 5.45434 14.1913C6.17397 14.2563 6.7442 14.8265 6.80922 15.5462C6.9023 16.5764 7.9881 17.2033 8.92684 16.7688C9.58257 16.4653 10.3615 16.674 10.7776 17.2647C11.3734 18.1103 12.6271 18.1103 13.2229 17.2647C13.639 16.674 14.4179 16.4653 15.0737 16.7688C16.0124 17.2033 17.0982 16.5764 17.1913 15.5462C17.2563 14.8265 17.8265 14.2563 18.5462 14.1913C19.5764 14.0982 20.2033 13.0124 19.7688 12.0737C19.4653 11.4179 19.674 10.639 20.2647 10.2229C21.1103 9.62713 21.1103 8.37336 20.2647 7.77763C19.674 7.36151 19.4653 6.58257 19.7688 5.92684C20.2033 4.9881 19.5764 3.9023 18.5462 3.80922C17.8265 3.7442 17.2563 3.17397 17.1913 2.45434C17.0982 1.42411 16.0124 0.797225 15.0737 1.23173C14.4179 1.53523 13.639 1.32652 13.2229 0.73581ZM12.0002 16.0002C15.8662 16.0002 19.0002 12.8662 19.0002 9.00025C19.0002 5.13425 15.8662 2.00025 12.0002 2.00025C8.13425 2.00025 5.00025 5.13425 5.00025 9.00025C5.00025 12.8662 8.13425 16.0002 12.0002 16.0002Z" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" stroke="black" stroke-opacity="0.1"/>
            <circle cx="12" cy="9" r="6.5" stroke="white"/>
            <path d="M9.64656 12V11.08L10.4866 10.32C10.8399 10 11.1499 9.71333 11.4166 9.46C11.6832 9.20667 11.9032 8.97 12.0766 8.75C12.2499 8.53 12.3799 8.32667 12.4666 8.14C12.5599 7.94667 12.6099 7.75333 12.6166 7.56C12.6166 7.29333 12.5332 7.07 12.3666 6.89C12.1999 6.70333 11.9266 6.61 11.5466 6.61C11.2666 6.61 11.0066 6.66667 10.7666 6.78C10.5332 6.89333 10.3299 7.01667 10.1566 7.15L9.72656 6.06C9.97323 5.87333 10.2799 5.71667 10.6466 5.59C11.0132 5.45667 11.4166 5.39 11.8566 5.39C12.2232 5.39 12.5466 5.44333 12.8266 5.55C13.1132 5.65 13.3532 5.79 13.5466 5.97C13.7399 6.15 13.8866 6.36667 13.9866 6.62C14.0866 6.86667 14.1366 7.13667 14.1366 7.43C14.1366 7.70333 14.0899 7.96333 13.9966 8.21C13.9032 8.45667 13.7766 8.69667 13.6166 8.93C13.4632 9.15667 13.2799 9.38 13.0666 9.6C12.8599 9.81333 12.6399 10.0233 12.4066 10.23L11.8066 10.73V10.75H14.2566V12H9.64656Z" fill="white"/>
        </g>
        <g v-if="rank == 3">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.98519 15.3862L3.04883 20.0004L6.54883 19.5004L8.04883 23.0004L10.5628 17.9725C10.0887 17.4146 9.29442 17.2332 8.619 17.5458C7.58639 18.0238 6.392 17.3342 6.28961 16.2009C6.26224 15.898 6.15345 15.619 5.98519 15.3862ZM13.4679 17.9368L15.9997 23.0004L17.4997 19.5004L20.9997 20.0004L18.0407 15.3506C17.8575 15.5904 17.7387 15.8824 17.7099 16.2009C17.6075 17.3342 16.4131 18.0238 15.3805 17.5458C14.7197 17.24 13.9451 17.407 13.4679 17.9368Z" fill="#E2000F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2229 0.73581C12.6271 -0.109853 11.3734 -0.109853 10.7776 0.73581C10.3615 1.32652 9.58257 1.53523 8.92684 1.23173C7.9881 0.797225 6.9023 1.42411 6.80922 2.45434C6.7442 3.17397 6.17397 3.7442 5.45434 3.80922C4.42411 3.9023 3.79722 4.9881 4.23173 5.92684C4.53523 6.58257 4.32652 7.36151 3.73581 7.77763C2.89015 8.37336 2.89015 9.62713 3.73581 10.2229C4.32652 10.639 4.53523 11.4179 4.23173 12.0737C3.79722 13.0124 4.42411 14.0982 5.45434 14.1913C6.17397 14.2563 6.7442 14.8265 6.80922 15.5462C6.9023 16.5764 7.9881 17.2033 8.92684 16.7688C9.58257 16.4653 10.3615 16.674 10.7776 17.2647C11.3734 18.1103 12.6271 18.1103 13.2229 17.2647C13.639 16.674 14.4179 16.4653 15.0737 16.7688C16.0124 17.2033 17.0982 16.5764 17.1913 15.5462C17.2563 14.8265 17.8265 14.2563 18.5462 14.1913C19.5764 14.0982 20.2033 13.0124 19.7688 12.0737C19.4653 11.4179 19.674 10.639 20.2647 10.2229C21.1103 9.62713 21.1103 8.37336 20.2647 7.77763C19.674 7.36151 19.4653 6.58257 19.7688 5.92684C20.2033 4.9881 19.5764 3.9023 18.5462 3.80922C17.8265 3.7442 17.2563 3.17397 17.1913 2.45434C17.0982 1.42411 16.0124 0.797225 15.0737 1.23173C14.4179 1.53523 13.639 1.32652 13.2229 0.73581ZM12.0002 16.0002C15.8662 16.0002 19.0002 12.8662 19.0002 9.00025C19.0002 5.13425 15.8662 2.00025 12.0002 2.00025C8.13425 2.00025 5.00025 5.13425 5.00025 9.00025C5.00025 12.8662 8.13425 16.0002 12.0002 16.0002Z" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="7" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" fill="#E2000F"/>
            <circle cx="12" cy="9" r="6.5" stroke="black" stroke-opacity="0.1"/>
            <circle cx="12" cy="9" r="6.5" stroke="white"/>
            <path d="M9.91656 10.51C9.98323 10.5433 10.0699 10.5833 10.1766 10.63C10.2832 10.6767 10.4032 10.72 10.5366 10.76C10.6699 10.8 10.8132 10.8367 10.9666 10.87C11.1199 10.8967 11.2732 10.91 11.4266 10.91C11.8266 10.91 12.1232 10.8267 12.3166 10.66C12.5166 10.4933 12.6166 10.2867 12.6166 10.04C12.6166 9.88 12.5799 9.74333 12.5066 9.63C12.4332 9.51 12.3332 9.41333 12.2066 9.34C12.0866 9.26 11.9466 9.20333 11.7866 9.17C11.6332 9.13 11.4732 9.11 11.3066 9.11H10.6866V8.02H11.2766C11.4032 8.02 11.5332 8.00667 11.6666 7.98C11.8066 7.95333 11.9332 7.91 12.0466 7.85C12.1599 7.79 12.2532 7.71333 12.3266 7.62C12.3999 7.52667 12.4366 7.41 12.4366 7.27C12.4366 7.07667 12.3566 6.91667 12.1966 6.79C12.0366 6.65667 11.7966 6.59 11.4766 6.59C11.3432 6.59 11.2099 6.60333 11.0766 6.63C10.9432 6.65667 10.8166 6.69 10.6966 6.73C10.5766 6.76333 10.4666 6.80333 10.3666 6.85C10.2666 6.89667 10.1832 6.94 10.1166 6.98L9.80656 5.88C9.9999 5.75333 10.2766 5.64 10.6366 5.54C11.0032 5.44 11.3966 5.39 11.8166 5.39C12.1632 5.39 12.4699 5.43333 12.7366 5.52C13.0099 5.60667 13.2366 5.72333 13.4166 5.87C13.6032 6.01667 13.7432 6.19 13.8366 6.39C13.9299 6.58333 13.9766 6.79 13.9766 7.01C13.9766 7.35667 13.8766 7.66 13.6766 7.92C13.4832 8.18 13.1866 8.38 12.7866 8.52V8.54C12.9799 8.57333 13.1632 8.63667 13.3366 8.73C13.5099 8.81667 13.6599 8.93 13.7866 9.07C13.9132 9.20333 14.0132 9.36 14.0866 9.54C14.1599 9.71333 14.1966 9.90667 14.1966 10.12C14.1966 10.4067 14.1332 10.6733 14.0066 10.92C13.8866 11.16 13.7099 11.37 13.4766 11.55C13.2499 11.7233 12.9732 11.86 12.6466 11.96C12.3199 12.06 11.9499 12.11 11.5366 12.11C11.1166 12.11 10.7366 12.0633 10.3966 11.97C10.0632 11.8767 9.7999 11.77 9.60656 11.65L9.91656 10.51Z" fill="white"/>
        </g>
    </svg>
</template>

<script>
export default({
    name: 'BadgeSVG',
    props: {
        rank: Number
    },
})
</script>

<style>
</style>
