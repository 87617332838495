<template>
    <svg v-if="option == 1" width="55" height="103" viewBox="0 0 55 103" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.5479 98.731L35.7564 99.6458L36.368 100.498L37.3408 101.23L38.6086 101.792L40.0851 102.145L41.6694 102.265L43.2539 102.145L44.7303 101.792L45.9981 101.23L46.971 100.498L47.5825 99.6458L47.7911 98.731V97.0257L47.5825 97.9405L46.971 98.7929L45.9981 99.5249L44.7303 100.087L43.2539 100.44L41.6694 100.56L40.0851 100.44L38.6086 100.087L37.3408 99.5249L36.368 98.7929L35.7564 97.9405L35.5479 97.0257V98.731Z" fill="#D6D6D6"/>
        <path d="M38.0779 94.2002L37.3408 94.5267L36.368 95.2586L35.7564 96.111L35.5479 97.0257L35.7564 97.9405L36.368 98.7929L37.3408 99.5249L38.6086 100.087L40.0851 100.44L41.6694 100.56L43.2539 100.44L44.7303 100.087L45.9981 99.5249L46.971 98.7929L47.5825 97.9405L47.7911 97.0257L47.5825 96.111L46.971 95.2586L45.9981 94.5267L45.2611 94.2002H38.0779Z" fill="#E1E1E1"/>
        <path d="M34.6566 30.5282L34.6601 30.5308L35.3618 30.8293L36.0155 30.894L36.577 30.7207L37.5513 30.1582L38.5255 29.5957L39.4999 29.0332L39.9488 28.774L40.9231 28.2115L41.8974 27.649L42.8716 27.0865L43.3347 26.8192L44.309 26.2567L45.2833 25.6942L46.2575 25.1317L47.2318 24.5692L48.2061 24.0067L49.1804 23.4442L50.1546 22.8817L50.765 22.5294L51.1957 22.1298L51.4666 21.5312L51.559 20.7744L51.4666 19.9108L51.1957 18.9996L50.765 18.1025L50.2036 17.2809L49.5497 16.5907L48.8481 16.079L48.1465 15.7806L47.4927 15.7158L46.9313 15.8892L45.9569 16.4517L44.9827 17.0142L44.0084 17.5766L43.0341 18.1391L42.0598 18.7016L41.0856 19.2641L40.1113 19.8266L39.137 20.3891L38.1627 20.9516L37.1885 21.5141L36.2142 22.0766L35.2398 22.6391L34.2656 23.2016L33.2913 23.7641L32.7432 24.0805L32.3125 24.48L32.0417 25.0786L31.9492 25.8355L31.9498 25.8398" fill="#E1E1E1"/>
        <path d="M37.9971 97.0257L38.1223 97.5745L38.4891 98.0859L39.0728 98.525L39.8333 98.862L40.719 99.0738L41.6695 99.146L42.62 99.0738L43.5057 98.862L44.2664 98.525L44.85 98.0859L45.2169 97.5745L45.342 97.0257L43.3345 26.8193L39.9486 28.7741L37.9971 97.0257Z" fill="#E8E8E8"/>
        <path d="M34.6601 30.5309L35.3618 30.8294L36.0155 30.894L36.577 30.7208L37.0078 30.3212L37.2786 29.7226L37.3709 28.9658L37.2786 28.1022L37.0078 27.191L36.577 26.2939L36.0155 25.4723L35.3618 24.7821L34.6601 24.2704L33.9585 23.972L33.3047 23.9072L32.7432 24.0806L32.3125 24.4801L32.0417 25.0787L31.9492 25.8356L34.6601 30.5309Z" fill="#BEBEBF"/>
        <path d="M46.673 0L45.9355 0.4257L48.3886 1.91824L49.1261 1.49242L46.673 0Z" fill="#E6E6E6"/>
        <path d="M45.9355 0.4257L30.9512 20.7961L33.8356 28.4769L48.3886 1.91824L45.9355 0.4257Z" fill="#D6D6D6"/>
        <path d="M49.1261 1.49242L48.3886 1.91824L33.8356 28.4769L34.5731 28.0512L49.1261 1.49242Z" fill="#D6D6D6"/>
        <path d="M0.737547 10.7876L0 11.2134L33.497 27.6254L33.5122 27.6166L33.1573 26.6718L0.737547 10.7876Z" fill="#E1E1E1"/>
        <path d="M0 11.2134L1.16932 14.3797L30.4275 31.7453L33.497 27.6254L0 11.2134Z" fill="#D6D6D6"/>
        <path d="M33.6028 27.8574L33.1738 28.105L39.1277 31.6659L39.8652 31.24L34.5526 28.0627L33.6028 27.8574Z" fill="#E1E1E1"/>
        <path d="M53.4016 69.4019L54.1391 68.9761L39.8652 31.24L39.1277 31.6659L53.4016 69.4019Z" fill="#E1E1E1"/>
        <path d="M53.4016 69.4019L39.1277 31.6659L33.1738 28.105L52.1178 71.0756L53.4016 69.4019Z" fill="#D6D6D6"/>
        <path d="M54.1391 68.9761L53.4016 69.4019L52.1178 71.0756L52.8554 70.6498L54.1391 68.9761Z" fill="#E1E1E1"/>
        <path d="M33.306 25.0565L31.6932 25.9876L31.3458 26.2483L31.0475 26.5251L30.7828 26.8496L30.605 27.2312L30.446 27.5827L30.3859 27.954L30.3398 28.3319L30.3859 28.763L30.428 28.9755L30.5342 29.3329L30.7032 29.6848L30.9235 30.0072L31.18 30.2778L31.3429 30.4206L31.6932 30.6761L32.0435 30.8251L32.3952 30.9587L32.779 30.9967L33.1984 31.0336L33.6119 30.9667L34.0007 30.8466L34.4001 30.6761L36.013 29.7449" fill="#E1E1E1"/>
        <path d="M36.013 29.7449L36.3171 29.4628L36.5083 29.0401L36.5736 28.5057L36.5083 27.8961L36.3171 27.2527L36.013 26.6193L35.6165 26.0392L35.1548 25.5519L34.6595 25.1906L34.1641 24.9799L33.7025 24.9341L33.306 25.0565" fill="#E1E1E1"/>
    </svg>
    <svg v-else-if="option == 2" width="56" height="124" viewBox="0 0 56 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.835 119.468L23.0435 120.383L23.6551 121.235L24.6279 121.967L25.8957 122.529L27.3722 122.882L28.9565 123.003L30.541 122.882L32.0174 122.529L33.2852 121.967L34.2581 121.235L34.8696 120.383L35.0782 119.468V117.763L34.8696 118.678L34.2581 119.53L33.2852 120.262L32.0174 120.824L30.541 121.177L28.9565 121.297L27.3722 121.177L25.8957 120.824L24.6279 120.262L23.6551 119.53L23.0435 118.678L22.835 117.763V119.468Z" fill="#D6D6D6"/>
        <path d="M25.365 114.938L24.6279 115.264L23.6551 115.996L23.0435 116.848L22.835 117.763L23.0435 118.678L23.6551 119.53L24.6279 120.262L25.8957 120.824L27.3722 121.177L28.9565 121.297L30.541 121.177L32.0174 120.824L33.2852 120.262L34.2581 119.53L34.8696 118.678L35.0782 117.763L34.8696 116.848L34.2581 115.996L33.2852 115.264L32.5482 114.938H25.365Z" fill="#E1E1E1"/>
        <path d="M21.9437 51.2655L21.9472 51.2681L22.6489 51.5666L23.3026 51.6313L23.8641 51.458L24.8384 50.8955L25.8126 50.333L26.787 49.7705L27.2359 49.5113L28.2102 48.9488L29.1845 48.3863L30.1587 47.8238L30.6218 47.5565L31.5961 46.994L32.5704 46.4315L33.5446 45.869L34.5189 45.3065L35.4932 44.744L36.4675 44.1815L37.4417 43.619L38.0521 43.2667L38.4828 42.8671L38.7537 42.2685L38.8461 41.5117L38.7537 40.6481L38.4828 39.7369L38.0521 38.8398L37.4907 38.0182L36.8368 37.328L36.1352 36.8163L35.4336 36.5179L34.7798 36.4531L34.2184 36.6265L33.244 37.189L32.2698 37.7515L31.2955 38.3139L30.3212 38.8764L29.3469 39.4389L28.3727 40.0014L27.3984 40.5639L26.4241 41.1264L25.4498 41.6889L24.4756 42.2514L23.5013 42.8139L22.527 43.3764L21.5527 43.9389L20.5785 44.5014L20.0304 44.8178L19.5996 45.2173L19.3288 45.8159L19.2363 46.5728L19.2369 46.5771" fill="#E1E1E1"/>
        <path d="M25.2842 117.763L25.4094 118.312L25.7763 118.823L26.3599 119.262L27.1204 119.599L28.0061 119.811L28.9566 119.883L29.9071 119.811L30.7928 119.599L31.5535 119.262L32.1371 118.823L32.504 118.312L32.6291 117.763L30.6216 47.5566L27.2357 49.5114L25.2842 117.763Z" fill="#E8E8E8"/>
        <path d="M21.9472 51.2682L22.6489 51.5667L23.3026 51.6313L23.8641 51.4581L24.2949 51.0585L24.5657 50.4599L24.658 49.7031L24.5657 48.8395L24.2949 47.9283L23.8641 47.0312L23.3026 46.2096L22.6489 45.5194L21.9472 45.0077L21.2456 44.7093L20.5918 44.6445L20.0304 44.8179L19.5996 45.2174L19.3288 45.816L19.2363 46.5729L21.9472 51.2682Z" fill="#BEBEBF"/>
        <path d="M7.70657 5.41312L6.03809 6.52777L15.033 43.8728L20.8507 48.6989L7.70657 5.41312Z" fill="#D6D6D6"/>
        <path d="M8.44412 4.9873L7.70657 5.41312L20.8507 48.6989L21.1633 48.5185L21.5761 48.2333L8.44412 4.9873Z" fill="#E1E1E1"/>
        <path d="M20.2007 48.1838L0 67.5139L2.39266 69.5248L22.0089 55.7792L20.2007 48.1838Z" fill="#D6D6D6"/>
        <path d="M20.218 48.1738L20.2007 48.1838L22.0089 55.7792L22.7465 55.3534L21.4105 49.7422L21.2336 48.8181L20.218 48.1738Z" fill="#E1E1E1"/>
        <path d="M21.1888 48.463L21.1506 48.4892L20.4463 48.9758L53.7911 72.9315C53.7911 72.9315 53.3497 71.0266 53.0669 69.806C41.8935 60.5899 24.4557 46.2066 24.4557 46.2066L21.6017 48.1778L21.1888 48.463Z" fill="#D6D6D6"/>
        <path d="M53.0669 69.806L53.8044 69.3802L25.1932 45.7808L24.4557 46.2066L53.0669 69.806Z" fill="#E2E2E2"/>
        <path d="M53.8044 69.3802L53.0669 69.806L53.7911 72.9315L54.5286 72.5057L53.8044 69.3802Z" fill="#E1E1E1"/>
        <path d="M20.5941 45.7938L18.9813 46.7249L18.6339 46.9856L18.3356 47.2624L18.0708 47.5869L17.8931 47.9685L17.7341 48.32L17.6739 48.6913L17.6279 49.0692L17.6739 49.5003L17.7161 49.7128L17.8223 50.0702L17.9913 50.4221L18.2116 50.7445L18.4681 51.0151L18.631 51.1579L18.9813 51.4134L19.3316 51.5624L19.6833 51.696L20.067 51.734L20.4864 51.7709L20.9 51.704L21.2888 51.5839L21.6882 51.4134L23.3011 50.4822" fill="#E1E1E1"/>
        <path d="M23.3011 50.4822L23.6052 50.2001L23.7964 49.7774L23.8617 49.243L23.7964 48.6334L23.6052 47.99L23.3011 47.3566L22.9046 46.7765L22.4429 46.2892L21.9476 45.9279L21.4521 45.7172L20.9905 45.6714L20.5941 45.7938" fill="#E1E1E1"/>
    </svg>
    <svg v-else-if="option == 3" width="63" height="113" viewBox="0 0 63 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.8232 108.468L31.0318 109.383L31.6434 110.235L32.6162 110.967L33.884 111.529L35.3605 111.882L36.9448 112.003L38.5293 111.882L40.0057 111.529L41.2735 110.967L42.2464 110.235L42.8579 109.383L43.0665 108.468V106.763L42.8579 107.678L42.2464 108.53L41.2735 109.262L40.0057 109.824L38.5293 110.177L36.9448 110.297L35.3605 110.177L33.884 109.824L32.6162 109.262L31.6434 108.53L31.0318 107.678L30.8232 106.763V108.468Z" fill="#D6D6D6"/>
        <path d="M33.3533 103.938L32.6162 104.264L31.6434 104.996L31.0318 105.848L30.8232 106.763L31.0318 107.678L31.6434 108.53L32.6162 109.262L33.884 109.824L35.3605 110.177L36.9448 110.297L38.5293 110.177L40.0057 109.824L41.2735 109.262L42.2464 108.53L42.8579 107.678L43.0665 106.763L42.8579 105.848L42.2464 104.996L41.2735 104.264L40.5365 103.938H33.3533Z" fill="#E1E1E1"/>
        <path d="M29.9311 40.2655L29.9345 40.2681L30.6362 40.5666L31.2899 40.6313L31.8514 40.458L32.8257 39.8955L33.7999 39.333L34.7743 38.7705L35.2232 38.5113L36.1975 37.9488L37.1718 37.3863L38.146 36.8238L38.6091 36.5565L39.5834 35.994L40.5577 35.4315L41.5319 34.869L42.5062 34.3065L43.4806 33.744L44.4548 33.1815L45.429 32.619L46.0394 32.2667L46.4701 31.8671L46.741 31.2685L46.8334 30.5117L46.741 29.6481L46.4701 28.7369L46.0394 27.8398L45.478 27.0182L44.8241 26.328L44.1225 25.8163L43.4209 25.5179L42.7671 25.4531L42.2057 25.6265L41.2313 26.189L40.2571 26.7515L39.2828 27.3139L38.3085 27.8764L37.3342 28.4389L36.36 29.0014L35.3857 29.5639L34.4114 30.1264L33.4371 30.6889L32.4629 31.2514L31.4886 31.8139L30.5143 32.3764L29.54 32.9389L28.5658 33.5014L28.0177 33.8178L27.5869 34.2173L27.3161 34.8159L27.2236 35.5728L27.2242 35.5771" fill="#E1E1E1"/>
        <path d="M33.2715 106.763L33.3967 107.312L33.7636 107.823L34.3472 108.262L35.1077 108.599L35.9934 108.811L36.9439 108.883L37.8944 108.811L38.7802 108.599L39.5408 108.262L40.1244 107.823L40.4913 107.312L40.6164 106.763L38.6089 36.5566L35.223 38.5114L33.2715 106.763Z" fill="#E8E8E8"/>
        <path d="M29.9345 40.2682L30.6362 40.5667L31.2899 40.6313L31.8514 40.4581L32.2822 40.0585L32.553 39.4599L32.6453 38.7031L32.553 37.8395L32.2822 36.9283L31.8514 36.0312L31.2899 35.2096L30.6362 34.5194L29.9345 34.0077L29.2329 33.7093L28.5791 33.6445L28.0177 33.8179L27.5869 34.2174L27.3161 34.816L27.2236 35.5729L29.9345 40.2682Z" fill="#BEBEBF"/>
        <path d="M0.301726 1.38821L0 4.02611L23.5752 37.6511L29.038 38.0686L0.301726 1.38821Z" fill="#D6D6D6"/>
        <path d="M1.03927 0.962402L0.301726 1.38821L29.038 38.0686L29.0616 38.0551L29.1846 36.8885L1.03927 0.962402Z" fill="#E2E2E2"/>
        <path d="M30.0011 76.627L33.2239 44.9054L28.4402 38.0488L27.7412 76.4266L30.0011 76.627Z" fill="#D6D6D6"/>
        <path d="M30.0011 76.627L30.7385 76.2012L33.9614 44.4796L33.2239 44.9054L30.0011 76.627Z" fill="#E1E1E1"/>
        <path d="M28.4799 38.0259L28.4402 38.0488L33.2239 44.9054L33.9614 44.4796L29.9583 38.7418L29.9254 38.0873L28.4799 38.0259Z" fill="#E1E1E1"/>
        <path d="M29.1106 37.5919L29.1029 37.6661L28.9834 38.7981L58.4188 37.1007L56.4606 34.2624L29.6626 32.359L29.1106 37.5919Z" fill="#D6D6D6"/>
        <path d="M56.4606 34.2624L57.198 33.8366L30.4001 31.9331L29.6626 32.359L56.4606 34.2624Z" fill="#E1E1E1"/>
        <path d="M57.198 33.8366L56.4606 34.2624L58.4188 37.1007L59.1563 36.6749L57.198 33.8366Z" fill="#E2E2E2"/>
        <path d="M28.5814 34.7938L26.9686 35.7249L26.6212 35.9856L26.3229 36.2624L26.0581 36.5869L25.8804 36.9685L25.7214 37.32L25.6612 37.6913L25.6152 38.0692L25.6612 38.5003L25.7034 38.7128L25.8096 39.0702L25.9786 39.4221L26.1989 39.7445L26.4554 40.0151L26.6183 40.1579L26.9686 40.4134L27.3189 40.5624L27.6706 40.696L28.0543 40.734L28.4737 40.7709L28.8873 40.704L29.2761 40.5839L29.6755 40.4134L31.2884 39.4822" fill="#E1E1E1"/>
        <path d="M31.2884 39.4822L31.5925 39.2001L31.7837 38.7774L31.849 38.243L31.7837 37.6334L31.5925 36.99L31.2884 36.3566L30.8919 35.7765L30.4302 35.2892L29.9349 34.9279L29.4394 34.7172L28.9779 34.6714L28.5814 34.7938" fill="#E1E1E1"/>
    </svg>




</template>

<script>
export default {
    name: 'WindTurbineSVG',
    data() {
        return {
        }
    },
    props: {
      option: Number
    },
}

</script>