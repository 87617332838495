<template>
    <InputToggle ref="toggler" @toggleInput="isActive = $refs.toggler.isActive">
        <template #knob>
            <MotorEfficiencySVG />
        </template>
        <template #input="slotProps">
            <SliderInput ref="input" @updateValue="value = parseInt($refs.input.sliderVal)" :values="[ $t('drive-system-ui-box.motor-efficiency.input.value1'), 'IE1' , 'IE2', 'IE3', 'IE4', 'IE5' ]" :defaultOpt="0" :isActive=slotProps.isActive />
        </template>
    </InputToggle>
</template>

<script>
    import InputToggle from '.././InputToggle.vue'
    import SliderInput from '.././SliderInput.vue'
    import MotorEfficiencySVG from '.././svg/icons/MotorEfficiencySVG.vue'

    export default({
        name: "MotorEfficiencyToggleInput",
        components: {
            InputToggle,
            SliderInput,
            MotorEfficiencySVG
        },
        data() {
            return {
            isActive: 0,
            value: null,
            }
        },
        watch: {
            isActive(newVal) {
                this.$store.commit('setInputStatus',  { input: 'motor-efficiency', activated: parseInt(newVal) });
            },
            value(newVal){
                this.$store.commit('setInputValue',  { input: 'motor-efficiency', value: parseInt(newVal) });
            }
        }
    })
</script>