<template>
    <svg width="60" height="72" viewBox="0 0 60 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.1701 62.6596V65.1339L30.2777 71.9999V69.5257L42.1701 62.6596Z" fill="#BCBCBC"/>
        <path d="M30.2777 71.9999L18.3853 65.1339V62.6596L30.2777 69.5257V71.9999Z" fill="#DDDDDD"/>
        <path d="M24.341 59.2212V62.7676L30.2777 66.1951L36.2145 62.7676V59.2212L42.1701 62.6596L30.2777 69.5257L18.3853 62.6596L24.341 59.2212Z" fill="white"/>
        <path d="M8.01086e-05 36.85L29.6219 0L57.8567 16.3012L43.0458 34.7262L28.2349 53.1512L8.01086e-05 36.85Z" fill="#F7F7F7"/>
        <path d="M29.5842 55.0708L24.341 52.0437L1.34932 38.7696L8.01086e-05 36.85L28.2349 53.1512L29.5842 55.0708Z" fill="#E1E1E1"/>
        <path d="M59.2061 18.2208L36.2145 46.8227L30.2777 54.208L29.5842 55.0708L28.2349 53.1512L43.0458 34.7262L57.8567 16.3012L59.2061 18.2208Z" fill="#E7E7E7"/>
        <path d="M29.5842 55.0708L30.2777 54.208V66.1951L24.341 62.7676V59.2212V52.0437L29.5842 55.0708Z" fill="#DDDDDD"/>
        <path d="M30.2777 54.208L36.2145 46.8227V59.2212V62.7676L30.2777 66.1951V54.208Z" fill="#BCBCBC"/>
        <path d="M50.9001 14.7441L54.6779 16.9253L50.6167 21.9774L46.8389 19.7963L50.9001 14.7441Z" fill="#515151"/>
        <path d="M46.588 12.2546L50.3658 14.4358L46.3047 19.4879L42.5269 17.3068L46.588 12.2546Z" fill="#515151"/>
        <path d="M42.2761 9.7652L46.0539 11.9462L41.9927 16.9984L38.215 14.8174L42.2761 9.7652Z" fill="#515151"/>
        <path d="M37.9641 7.27569L41.7419 9.45673L37.6808 14.5089L33.903 12.3278L37.9641 7.27569Z" fill="#515151"/>
        <path d="M33.6522 4.78614L37.43 6.96726L33.3687 12.0195L29.5909 9.8383L33.6522 4.78614Z" fill="#515151"/>
        <path d="M29.3402 2.29663L33.118 4.47775L29.0568 9.5299L25.279 7.34879L29.3402 2.29663Z" fill="#515151"/>
        <path d="M24.8164 7.9243L28.5941 10.1054L24.533 15.1576L20.7551 12.9765L24.8164 7.9243Z" fill="#515151"/>
        <path d="M29.1284 10.4138L32.9062 12.5949L28.845 17.6471L25.0672 15.466L29.1284 10.4138Z" fill="#515151"/>
        <path d="M33.4403 12.9034L37.2181 15.0845L33.1569 20.1366L29.3792 17.9555L33.4403 12.9034Z" fill="#515151"/>
        <path d="M37.7523 15.3929L41.53 17.5739L37.4689 22.6261L33.6911 20.445L37.7523 15.3929Z" fill="#515151"/>
        <path d="M38.0031 22.9345L42.0643 17.8823L45.8421 20.0634L41.7809 25.1156L38.0031 22.9345Z" fill="#515151"/>
        <path d="M46.3762 20.3718L50.154 22.5529L46.0928 27.6051L42.3151 25.424L46.3762 20.3718Z" fill="#515151"/>
        <path d="M41.8525 25.9995L45.6303 28.1806L41.5691 33.2328L37.7913 31.0517L41.8525 25.9995Z" fill="#515151"/>
        <path d="M37.5404 23.5101L41.3182 25.6911L37.2571 30.7432L33.4793 28.5622L37.5404 23.5101Z" fill="#515151"/>
        <path d="M33.2285 21.0205L37.0063 23.2016L32.9452 28.2537L29.1674 26.0727L33.2285 21.0205Z" fill="#515151"/>
        <path d="M28.9165 18.531L32.6943 20.7121L28.6332 25.7643L24.8553 23.5832L28.9165 18.531Z" fill="#515151"/>
        <path d="M24.6046 16.0415L28.3824 18.2226L24.3211 23.2748L20.5433 21.0936L24.6046 16.0415Z" fill="#515151"/>
        <path d="M20.2926 13.552L24.0704 15.7331L20.0092 20.7852L16.2314 18.6041L20.2926 13.552Z" fill="#515151"/>
        <path d="M11.7076 24.2318L15.7688 19.1796L19.5466 21.3608L15.4854 26.4129L11.7076 24.2318Z" fill="#515151"/>
        <path d="M16.0196 26.7213L20.0808 21.6692L23.8586 23.8503L19.7974 28.9024L16.0196 26.7213Z" fill="#515151"/>
        <path d="M24.3928 24.1587L28.1706 26.3398L24.1093 31.3919L20.3316 29.2108L24.3928 24.1587Z" fill="#515151"/>
        <path d="M24.6435 31.7003L28.7047 26.6482L32.4825 28.8292L28.4213 33.8814L24.6435 31.7003Z" fill="#515151"/>
        <path d="M28.9556 34.1899L33.0167 29.1377L36.7945 31.3188L32.7333 36.3709L28.9556 34.1899Z" fill="#515151"/>
        <path d="M37.3286 31.6272L41.1064 33.8083L37.0453 38.8604L33.2675 36.6793L37.3286 31.6272Z" fill="#515151"/>
        <path d="M28.7437 42.307L32.8048 37.2548L36.5826 39.436L32.5215 44.4881L28.7437 42.307Z" fill="#515151"/>
        <path d="M24.2199 47.9347L28.2811 42.8825L32.0588 45.0636L27.9977 50.1158L24.2199 47.9347Z" fill="#515151"/>
        <path d="M19.908 45.4452L23.9691 40.393L27.7469 42.5741L23.6857 47.6263L19.908 45.4452Z" fill="#515151"/>
        <path d="M28.4929 34.7654L32.2707 36.9464L28.2096 41.9986L28.2094 41.9986L24.4317 39.8176L28.4929 34.7654Z" fill="#515151"/>
        <path d="M24.181 32.2759L27.9587 34.4569L23.8976 39.5091L20.1198 37.328L24.181 32.2759Z" fill="#515151"/>
        <path d="M15.5959 42.9557L15.5959 42.9557L19.657 37.9035L23.4348 40.0846L19.3737 45.1368L15.5959 42.9557Z" fill="#515151"/>
        <path d="M15.3452 35.414L19.123 37.5951L15.0617 42.6473L11.284 40.4662L15.3452 35.414Z" fill="#515151"/>
        <path d="M23.6466 31.9675L23.6467 31.9675L19.5856 37.0196L15.8078 34.8385L15.8077 34.8385L19.8688 29.7863L23.6466 31.9675Z" fill="#515151"/>
        <path d="M11.4958 32.349L15.557 27.2968L19.3348 29.4779L15.2736 34.5301L11.4958 32.349Z" fill="#515151"/>
        <path d="M14.8108 35.1056L14.811 35.1056L10.7498 40.1578L6.97199 37.9767L6.97189 37.9767L11.0331 32.9245L14.8108 35.1056Z" fill="#515151"/>
        <path d="M10.4989 32.6161L6.72122 30.4351L2.65998 35.4872L6.43781 37.6683L10.4989 32.6161Z" fill="#515151"/>
        <path d="M11.245 24.8074L15.0228 26.9884L10.9616 32.0406L7.18385 29.8596L11.245 24.8074Z" fill="#515151"/>
        <path d="M33.118 4.47775L33.3776 4.8471L29.3164 9.89926L29.0568 9.5299L33.118 4.47775Z" fill="#363636"/>
        <path d="M37.43 6.96726L37.6896 7.33661L33.6284 12.3888L33.3687 12.0195L37.43 6.96726Z" fill="#363636"/>
        <path d="M41.7419 9.45673L42.0016 9.82616L37.9404 14.8783L37.6808 14.5089L41.7419 9.45673Z" fill="#363636"/>
        <path d="M46.0539 11.9462L46.3135 12.3156L42.2524 17.3678L41.9927 16.9984L46.0539 11.9462Z" fill="#363636"/>
        <path d="M50.3658 14.4358L50.6254 14.8051L46.5643 19.8573L46.3047 19.4879L50.3658 14.4358Z" fill="#363636"/>
        <path d="M54.6779 16.9253L54.9375 17.2946L50.8763 22.3468L50.6167 21.9774L54.6779 16.9253Z" fill="#363636"/>
        <path d="M28.5941 10.1054L28.8538 10.4748L24.7926 15.5269L24.533 15.1576L28.5941 10.1054Z" fill="#363636"/>
        <path d="M32.9062 12.5949L33.1658 12.9643L29.1046 18.0164L28.845 17.6471L32.9062 12.5949Z" fill="#363636"/>
        <path d="M37.2181 15.0845L37.4778 15.4538L33.4166 20.506L33.1569 20.1366L37.2181 15.0845Z" fill="#363636"/>
        <path d="M41.53 17.5739L41.7897 17.9433L37.7285 22.9955L37.4689 22.6261L41.53 17.5739Z" fill="#363636"/>
        <path d="M45.8421 20.0634L46.1017 20.4328L42.0406 25.4849L41.7809 25.1156L45.8421 20.0634Z" fill="#363636"/>
        <path d="M50.154 22.5529L50.4136 22.9223L46.3525 27.9744L46.0928 27.6051L50.154 22.5529Z" fill="#363636"/>
        <path d="M45.6303 28.1806L45.8899 28.55L41.8288 33.6021L41.5691 33.2328L45.6303 28.1806Z" fill="#363636"/>
        <path d="M41.3182 25.6911L41.5779 26.0604L37.5167 31.1126L37.2571 30.7432L41.3182 25.6911Z" fill="#363636"/>
        <path d="M37.0063 23.2016L37.266 23.571L33.2048 28.6232L32.9452 28.2537L37.0063 23.2016Z" fill="#363636"/>
        <path d="M32.6943 20.7121L32.9539 21.0815L28.8927 26.1336L28.6332 25.7643L32.6943 20.7121Z" fill="#363636"/>
        <path d="M28.3824 18.2226L28.642 18.592L24.5808 23.6441L24.3211 23.2748L28.3824 18.2226Z" fill="#363636"/>
        <path d="M24.0704 15.7331L24.33 16.1024L20.2688 21.1546L20.0092 20.7852L24.0704 15.7331Z" fill="#363636"/>
        <path d="M19.5466 21.3608L19.8062 21.7301L15.745 26.7823L15.4854 26.4129L19.5466 21.3608Z" fill="#363636"/>
        <path d="M23.8586 23.8503L24.1182 24.2196L20.057 29.2718L19.7974 28.9024L23.8586 23.8503Z" fill="#363636"/>
        <path d="M28.1706 26.3398L28.4302 26.7091L24.369 31.7613L24.1093 31.3919L28.1706 26.3398Z" fill="#363636"/>
        <path d="M32.4825 28.8292L32.7421 29.1987L28.6809 34.2508L28.4213 33.8814L32.4825 28.8292Z" fill="#363636"/>
        <path d="M36.7945 31.3188L37.0541 31.6881L32.993 36.7403L32.7333 36.3709L36.7945 31.3188Z" fill="#363636"/>
        <path d="M41.1064 33.8083L41.3661 34.1776L37.3049 39.2298L37.0453 38.8604L41.1064 33.8083Z" fill="#363636"/>
        <path d="M36.5826 39.436L36.8423 39.8053L32.7811 44.8575L32.5215 44.4881L36.5826 39.436Z" fill="#363636"/>
        <path d="M32.2707 36.9464L32.5303 37.3158L28.4691 42.368L28.2096 41.9986L32.2707 36.9464Z" fill="#363636"/>
        <path d="M27.9587 34.4569L28.2184 34.8263L24.1572 39.8785L23.8976 39.5091L27.9587 34.4569Z" fill="#363636"/>
        <path d="M23.6467 31.9675L23.9062 32.3368L19.8452 37.3889L19.5856 37.0196L23.6467 31.9675Z" fill="#363636"/>
        <path d="M19.3348 29.4779L19.5944 29.8473L15.5332 34.8995L15.2736 34.5301L19.3348 29.4779Z" fill="#363636"/>
        <path d="M15.0228 26.9884L15.2824 27.3578L11.2213 32.4099L10.9616 32.0406L15.0228 26.9884Z" fill="#363636"/>
        <path d="M32.0588 45.0636L32.3185 45.433L28.2573 50.4851L27.9977 50.1158L32.0588 45.0636Z" fill="#363636"/>
        <path d="M27.7469 42.5741L28.0066 42.9435L23.9454 47.9957L23.6857 47.6263L27.7469 42.5741Z" fill="#363636"/>
        <path d="M23.4348 40.0846L23.6944 40.454L19.6333 45.5062L19.3736 45.1368L19.3737 45.1368L23.4348 40.0846Z" fill="#363636"/>
        <path d="M19.123 37.5951L19.3826 37.9645L15.3214 43.0166L15.0617 42.6473L19.123 37.5951Z" fill="#363636"/>
        <path d="M14.811 35.1056L15.0705 35.475L11.0094 40.527L10.7498 40.1578L14.811 35.1056Z" fill="#363636"/>
        <path d="M2.91959 35.8566L2.65998 35.4872L6.43781 37.6683L6.69731 38.0376L2.91967 35.8566L2.91959 35.8566Z" fill="#6A6A69"/>
        <path d="M10.4989 32.6161L10.7586 32.9855L6.6974 38.0376L6.69731 38.0376L6.43781 37.6683L10.4989 32.6161Z" fill="#363636"/>
        <path d="M11.5436 40.8355L11.284 40.4662L15.0617 42.6473L15.3214 43.0166L11.5436 40.8355Z" fill="#6A6A69"/>
        <path d="M11.7554 32.7184L11.4958 32.349L15.2736 34.5301L15.5332 34.8995L11.7554 32.7184Z" fill="#6A6A69"/>
        <path d="M7.44347 30.2289L7.18385 29.8596L10.9616 32.0406L11.2213 32.4099L7.44347 30.2289Z" fill="#6A6A69"/>
        <path d="M20.3794 37.6974L20.1198 37.328L23.8976 39.5091L24.1572 39.8785L20.3794 37.6974Z" fill="#6A6A69"/>
        <path d="M15.8555 43.325L15.5959 42.9557L19.3737 45.1368L19.3736 45.1368L19.6333 45.5062L15.8555 43.325Z" fill="#6A6A69"/>
        <path d="M20.1676 45.8146L19.908 45.4452L23.6857 47.6263L23.9454 47.9957L20.1676 45.8146Z" fill="#6A6A69"/>
        <path d="M24.6913 40.1869L24.4317 39.8176L28.2094 41.9986L28.2096 41.9986L28.4691 42.368L24.6913 40.1869Z" fill="#6A6A69"/>
        <path d="M29.0033 42.6764L28.7437 42.307L32.5215 44.4881L32.7811 44.8575L29.0033 42.6764Z" fill="#6A6A69"/>
        <path d="M27.9977 50.1158L28.2573 50.4851L24.4795 48.3041L24.2199 47.9347L27.9977 50.1158Z" fill="#6A6A69"/>
        <path d="M33.5271 37.0488L33.2675 36.6793L37.0453 38.8604L37.3049 39.2298L33.5271 37.0488Z" fill="#6A6A69"/>
        <path d="M29.2152 34.5592L28.9556 34.1899L32.7333 36.3709L32.993 36.7403L29.2152 34.5592Z" fill="#6A6A69"/>
        <path d="M24.9031 32.0697L24.6435 31.7003L28.4213 33.8814L28.6809 34.2508L24.9031 32.0697Z" fill="#6A6A69"/>
        <path d="M20.5912 29.5802L20.3316 29.2108L24.1093 31.3919L24.369 31.7613L20.5912 29.5802Z" fill="#6A6A69"/>
        <path d="M16.2792 27.0907L16.0196 26.7213L19.7974 28.9024L20.057 29.2718L16.2792 27.0907Z" fill="#6A6A69"/>
        <path d="M11.9673 24.6012L11.7076 24.2318L15.4854 26.4129L15.745 26.7823L11.9673 24.6012Z" fill="#6A6A69"/>
        <path d="M16.4911 18.9736L16.2314 18.6041L20.0092 20.7852L20.2688 21.1546L16.4911 18.9736Z" fill="#6A6A69"/>
        <path d="M20.803 21.463L20.5433 21.0936L24.3211 23.2748L24.5808 23.6441L20.803 21.463Z" fill="#6A6A69"/>
        <path d="M25.1149 23.9525L24.8553 23.5832L28.6332 25.7643L28.8927 26.1336L28.8752 26.1234L25.1149 23.9525Z" fill="#6A6A69"/>
        <path d="M29.427 26.4421L29.1674 26.0727L32.9452 28.2537L33.2048 28.6232L29.427 26.4421Z" fill="#6A6A69"/>
        <path d="M33.7389 28.9316L33.4793 28.5622L37.2571 30.7432L37.5167 31.1126L33.7389 28.9316Z" fill="#6A6A69"/>
        <path d="M38.051 31.4211L37.7913 31.0517L41.5691 33.2328L41.8288 33.6021L38.051 31.4211Z" fill="#6A6A69"/>
        <path d="M42.5747 25.7933L42.3151 25.424L46.0928 27.6051L46.3525 27.9744L42.5747 25.7933Z" fill="#6A6A69"/>
        <path d="M47.0985 20.1657L46.8389 19.7963L50.6167 21.9774L50.8763 22.3468L47.0985 20.1657Z" fill="#6A6A69"/>
        <path d="M42.7865 17.6762L42.5269 17.3068L46.3047 19.4879L46.5643 19.8573L42.7865 17.6762Z" fill="#6A6A69"/>
        <path d="M38.2628 23.3039L38.0031 22.9345L41.7809 25.1156L42.0406 25.4849L38.2628 23.3039Z" fill="#6A6A69"/>
        <path d="M33.9507 20.8144L33.6911 20.445L37.4689 22.6261L37.7285 22.9955L33.9507 20.8144Z" fill="#6A6A69"/>
        <path d="M38.4746 15.1867L38.215 14.8174L41.9927 16.9984L42.2524 17.3678L38.4746 15.1867Z" fill="#6A6A69"/>
        <path d="M34.1626 12.6972L33.903 12.3278L37.6808 14.5089L37.9404 14.8783L34.1626 12.6972Z" fill="#6A6A69"/>
        <path d="M29.6388 18.3249L29.3792 17.9555L33.1569 20.1366L33.4166 20.506L29.6388 18.3249Z" fill="#6A6A69"/>
        <path d="M25.3268 15.8353L25.0672 15.466L28.845 17.6471L29.1046 18.0164L25.3268 15.8353Z" fill="#6A6A69"/>
        <path d="M29.8506 10.2077L29.5909 9.8383L33.3687 12.0195L33.6284 12.3888L29.8506 10.2077Z" fill="#6A6A69"/>
        <path d="M25.5386 7.71822L25.279 7.34879L29.0568 9.5299L29.3164 9.89926L25.5386 7.71822Z" fill="#6A6A69"/>
        <path d="M21.0148 13.3459L20.7551 12.9765L24.533 15.1576L24.7926 15.5269L21.0148 13.3459Z" fill="#6A6A69"/>
        <path d="M7.23151 38.3461L6.97199 37.9767L10.7498 40.1578L11.0094 40.527L11.0093 40.5271L7.23165 38.3461L7.23151 38.3461Z" fill="#6A6A69"/>
        <path d="M16.0673 35.2079L15.8078 34.8385L19.5856 37.0196L19.8452 37.3889L19.8451 37.389L16.0674 35.2079L16.0673 35.2079Z" fill="#6A6A69"/>
    </svg>
</template>

<script>
export default {
    name: 'SolarPanelSVG',
}
</script>