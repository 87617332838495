export const calculations = {
  created() {
  }, 
  computed: {
    gameActivated(){
      return this.$store.state.activated;
    },
    solarEnergyValue() {
      return parseInt(this.$store.state.energySources.photovoltaic.value);
    },
    greenEnergyValue() {
      return parseInt(this.$store.state.energySources.greenEnergy.value);
    },
    motorEfficiencyValue() {
      return parseInt(this.$store.state.driveSystem.motorEfficiency.value);
    },
    controlValue() {
      return this.$store.state.driveSystem.control.value;
    },
    regenerativeEnergyValue() {
      return this.$store.state.driveSystem.regenerativeEnergy.value;
    },
    solarEnergyActivated() {
      return this.$store.state.energySources.photovoltaic.activated;
    },
    greenEnergyActivated() {
      return this.$store.state.energySources.greenEnergy.activated;
    },
    motorEfficiencyActivated() {
      return this.$store.state.driveSystem.motorEfficiency.activated;
    },
    controlActivated() {
      return this.$store.state.driveSystem.control.activated;
    },
    regenerativeEnergyActivated() {
      return this.$store.state.driveSystem.regenerativeEnergy.activated;
    },
    energySourcesActivated() {
      return [this.$store.state.energySources.photovoltaic.wasActivated, this.$store.state.energySources.greenEnergy.wasActivated];
    }
  },
  watch: {
    energySourcesActivated(newVal){
      let checker = arr => arr.every(Boolean);
      if (checker(newVal) === true && this.$store.state.driveSystem.isVisible === false){
        this.$store.commit('activateOption',  {kind: 'drive-system', activated: true });
      }
    },
    gameActivated(newVal){
      if (newVal === true){
        this.trackEvent();
      } 
    },
    solarEnergyValue(){
      this.updateCalculations();
      // if (this.solarEnergyValue > 0 && this.greenEnergyValue > 0 && this.$store.state.driveSystem.isVisible === false){
      //   this.$store.commit('activateOption',  {kind: 'drive-system', activated: true });
      //   //this.$store.commit('activateOption',  {kind: 'room', activated: true });
      // }
      // else{
      //   // this.$store.commit('activateOption',  {kind: 'drive-system', activated: false });
      // }
    },
    greenEnergyValue(){
      this.updateCalculations();
      // if (this.solarEnergyValue > 0 && this.greenEnergyValue > 0 && this.$store.state.driveSystem.isVisible === false){
      //   this.$store.commit('activateOption',  {kind: 'drive-system', activated: true });
      // }
    },
    motorEfficiencyValue(newVal) {
      this.updateCalculations();
      if (newVal > 0 && this.$store.state.ranking.activated === false){
        this.$store.commit('activateOption',  {kind: 'ranking', activated: true });
      } 
    },
    controlValue(newVal){
      this.updateCalculations();
      if (newVal > 0 && this.$store.state.ranking.activated === false){
        this.$store.commit('activateOption',  {kind: 'ranking', activated: true });
      } 

      if (this.controlValue === 1){
        this.$store.commit('activateOption',  {kind: 'regenerative-energy', activated: true });
        this.$store.commit('activateOption',  {kind: 'feedback', activated: true });
      }
      else{
        this.$store.commit('activateOption',  {kind: 'regenerative-energy', activated: false });
      }
    },
    regenerativeEnergyValue(newVal) {
      this.updateCalculations();
    }
  },
  methods: {
     trackEvent(){
      if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({
          'event': 'virtualEvent',
          'eventAction': 'GamingPanel',
          'eventLabel': 'game-activation'
        });
      } else {
        // console.warn('Warning: The dataLayer object is not available.');
      }
    },
    updateCalculations(){
      this.calculateProduct("pump");
      this.calculateProduct("fan");
      this.calculateProduct("elevator");
      this.calculateProduct("lift");
      this.calculateProduct("conveyor");
      this.calculateProduct("compressor");

      this.calculateEnergySavings();
      this.calculateCO2Savings();
    },
    calculateProduct(product) {
      const { motorEfficiencyVal, controlVal, reEnergyVal } = this.$store.state[product];
      
      const motor = motorEfficiencyVal[this.motorEfficiencyValue];
      const control = controlVal[this.controlValue];
      const reEnergy = reEnergyVal[this.regenerativeEnergyValue];
    
      const energyConsumption = this.calcEnergyConsumption(motor, control, reEnergy);
      const roundedEnergyConsumption = this.round(energyConsumption, 2);
      const energySavings = this.round(100 - roundedEnergyConsumption, 2);
    
      this.$store.commit('setEnergyConsumptionAndSavings', {
        product: product,
        consumption: roundedEnergyConsumption,
        savings: energySavings,
      });
    },
    calcEnergyConsumption(a, b, c){
      return ((1 - (a/100)) * (1 - (b/100)) * (1 - (c/100))) *100; 
    },
    calculateEnergySavings(){
      const pump1Power = 37;
      const pump2Power = 15;
      const fan1Power = 22;
      const fan2Power = 30;
      const compressor1Power = 20;
      const compressor2Power = 15;
      const conveyorPower = 16;
      const liftPower = 7.5;
      const elevatorPower = 7.5;

      const powGesamt = pump1Power + pump2Power + fan1Power + fan2Power + compressor1Power + compressor2Power + conveyorPower + liftPower + elevatorPower;

      //Gesamtenergieeinsparung
      let EE = pump1Power/powGesamt*this.$store.state.pump.energySavings
            +  pump2Power/powGesamt*this.$store.state.pump.energySavings
            +  fan1Power/powGesamt*this.$store.state.fan.energySavings
            +  fan2Power/powGesamt*this.$store.state.fan.energySavings
            +  compressor1Power/powGesamt*this.$store.state.compressor.energySavings
            +  compressor2Power/powGesamt*this.$store.state.compressor.energySavings
            +  conveyorPower/powGesamt*this.$store.state.conveyor.energySavings
            +  liftPower/powGesamt*this.$store.state.lift.energySavings
            +  elevatorPower/powGesamt*this.$store.state.elevator.energySavings;

      EE = this.round(EE, 0)
      this.$store.commit('setTotalEnergySavings', {value: EE});

    },
    calculateCO2Savings(){

      const photovoltaic = this.solarEnergyValue;
      const greenEnergy = this.greenEnergyValue;
      const totalEnergySavings = this.$store.state.energySavingsTotal;

      let CO2E = totalEnergySavings + photovoltaic*10 + greenEnergy*10;
      
      if (CO2E >= 100){
        CO2E = 100; 
      }
      
      CO2E = this.round(CO2E);
      this.$store.commit('setTotalEmissionSavings', {value: CO2E});
      
    },
    round(value, precision) {
      const multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  }
}