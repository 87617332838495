<template>
     <InputToggle ref="toggler" @toggleInput="isActive = $refs.toggler.isActive">
        <template #knob>
            <RegenerativeEnergySVG/>
        </template>
        <template #input="slotProps">
            <TabInput 
            ref="input" @updateValue="value = $refs.input.tabVal"
            :values="[ $t('drive-system-ui-box.regenerative-energy.input.value1') , $t('drive-system-ui-box.regenerative-energy.input.value2') ]" :name="'2'" :defaultOpt="0" :isActive=slotProps.isActive 
            :inputPossible=inputPossible />
        </template>
    </InputToggle>
</template>

<script>
    import InputToggle from '.././InputToggle.vue'
    import TabInput from '.././TabInput.vue'
    import RegenerativeEnergySVG from '.././svg/icons/RegenerativeEnergySVG.vue'

    export default({
        name: "RegenerativeEnergyToggleInput",
        components: {
            InputToggle,
            TabInput,
            RegenerativeEnergySVG
        },
        data() {
            return {
            isActive: 0,
            value: 0,
            }
        },
        computed: {
            inputPossible() {
                return this.$store.state.driveSystem.regenerativeEnergy.inputPossible;
            }
        },
        watch: {
            isActive(newVal) {
                this.$store.commit('setInputStatus',  { input: 'regenerative-energy', activated: newVal });
            },
            value(newVal){
                this.$store.commit('setInputValue',  { input: 'regenerative-energy', value: newVal });
            }
        }
    })
</script>



