<template>
    <div class="cdgame__progress-bar-box">
        <div class="cdprogress">
            <svg class="cdprogress__svg" viewBox="0 0 20 20">
                <circle ref="background" class="cdprogress__svg--background" cx="10" cy="10" r="8"/>
                <circle ref="progress" class="cdprogress__svg--progress" cx="10" cy="10" r="8" :percentage="percentage"/>
            </svg>
            <div class="cdprogress__icon">
                <slot name="icon"></slot>
            </div>
        </div>
        <div class="cdgame__progress-info">
                <slot name="name"></slot>
                <span>
                <span class="cdgame__progress-percentage">{{ percentage }}</span>
                <span class="cdgame__progress-percentage"> %</span>
                </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RadialProgressBar',
    data() {
        return {
        }
    },
    props: {
        percentage: Number,
        name: String
    },
    methods: {
        updateProgress(updatePercentage) {
            const off = -51 - (51 / 100) * updatePercentage;
            this.$refs.progress.style.strokeDashoffset = off;
        }

    } ,
    mounted() {
        this.updateProgress(this.percentage);
    },
    watch: {
        percentage(newVal){
            this.updateProgress(newVal);
        }
    }
  }
</script>

<style scoped lang="scss">

    .cdgame{

    .cdgame__progress-bar-box{
    display: flex;
    flex-direction: row;
    padding: 1.25rem;
    }

    .cdgame__progress-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
    }


    .cdgame__progress-percentage:nth-child(1){
    font-size: 2.75rem;
    font-family: Myriad-Pro-Bold!important;
    }

    .cdgame__progress-percentage:nth-child(2){
    font-size: 1rem;
    font-family: Myriad-Pro-Bold!important;
    }
    .cdprogress{
        display: flex;
        position: relative;
        //min-width: 4vw;
        width: 5rem;
        align-items: center;
        justify-content: center;
    }

    .cdprogress__icon{
        display: flex;
        position: absolute;
    }

    .cdprogress__svg{
        width: 100%;
        transform: rotate(270deg);
    }
    .cdprogress__svg--background
    {
        fill: none;
        stroke: #f1f1f1;
        stroke-width: 0.15rem;
        
    }
    .cdprogress__svg--progress
    {
        fill: none;
        stroke: #2ecc71;
        stroke-width: 0.15rem;
        stroke-dasharray: 51 51;  
        stroke-dashoffset: -51;
        transition: all 0.3s ease-in-out;

    }
  

    @media screen and (max-width: 992px) {

    .cdgame__progress-bar-box{
        padding: 1rem;
    }
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
    .cdprogress{
        width: 14vw;
        min-width: 4rem;
    }

    .cdgame__progress-bar-box{
        padding: 1rem;
    }
    }

    @media screen and (max-width: 575px) {

        .cdprogress{
            width: 14vw;
        }

    .cdgame__progress-bar-box{
            padding: 0.25rem;
        }
    }

    }
 
</style>