<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_493_9680)">
        <path d="M7.38048 21.01C7.87048 21.5 8.66048 21.5 9.15048 21.01L17.4605 12.7C17.8505 12.31 17.8505 11.68 17.4605 11.29L9.15048 2.98005C8.66048 2.49005 7.87048 2.49005 7.38048 2.98005C6.89048 3.47005 6.89048 4.26005 7.38048 4.75005L14.6205 12L7.37048 19.25C6.89048 19.73 6.89048 20.53 7.38048 21.01V21.01Z" fill="black" fill-opacity="0.1"/>
        </g>
        <defs>
        <clipPath id="clip0_493_9680">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>

export default ({
    name: 'ArrowSVG'
})

</script>