<template>
    <InputToggle ref="toggler" @toggleInput="isActive = $refs.toggler.isActive">
        <template #knob>
            <ControlSVG />
        </template>
        <template #input="slotProps">      
            <TabInput ref="input" @updateValue="value = $refs.input.tabVal" 
            :values="[ $t('drive-system-ui-box.control.input.value1'), $t('drive-system-ui-box.control.input.value2'), $t('drive-system-ui-box.control.input.value3') ]" 
            :name="'1'" :defaultOpt="0" :isActive=slotProps.isActive />
        </template>
    </InputToggle>
</template>

<script>
    import InputToggle from '.././InputToggle.vue'
    import TabInput from '.././TabInput.vue'
    import ControlSVG from '.././svg/icons/ControlSVG.vue'

    export default({
        name: "ControlToggleInput",
        components: {
            InputToggle,
            TabInput,
            ControlSVG
        },
        data() {
            return {
            isActive: 0,
            value: null,
            }
        },
        watch: {
            isActive(newVal) {
                this.$store.commit('setInputStatus',  { input: 'control', activated: newVal });
            },
            value(newVal){
                this.$store.commit('setInputValue',  { input: 'control', value: newVal });
            }
        }
    })
</script>