<template>
    <ul class="cdranking" id="cdranking">
        <HiddenChampinion class="cdlist-entry__pump" :name='$t("champions-ui-box.champions.pump")' :variableName="'pump'"
        :uniqueRank=this.$store.state.pump.rank :percent=products[0].percentage :data-position=products[0].rank
        @mouseover.native="$emit('hoverEnter', 'pump', 1.35)" @mouseout.native="$emit('hoverOut', 'pump')"
        @mousedown.native="$emit('click', 'pump')">
            <template #icon>
                <PumpSVG/>
            </template>
        </HiddenChampinion>
        <HiddenChampinion class="cdlist-entry__fan" :name='$t("champions-ui-box.champions.fan")' :variableName="'fan'"
        :uniqueRank=this.$store.state.fan.rank :percent=products[1].percentage :data-position=products[1].rank
        @mouseover.native="$emit('hoverEnter', 'fan', 1.35)" @mouseout.native="$emit('hoverOut', 'fan')"
        @mousedown.native="$emit('click', 'fan')">
            <template #icon>
                <FanSVG/>
            </template>
        </HiddenChampinion>
        <HiddenChampinion class="cdlist-entry__compressor" :name='$t("champions-ui-box.champions.compressor")' :variableName="'compressor'"
        :uniqueRank=this.$store.state.compressor.rank :percent=products[4].percentage :data-position=products[4].rank
        @mouseover.native="$emit('hoverEnter', 'compressor', 1.35)" @mouseout.native="$emit('hoverOut', 'compressor')"
        @mousedown.native="$emit('click', 'compressor')">
            <template #icon>
                <CompressorSVG/>
            </template>
        </HiddenChampinion>
        <HiddenChampinion class="cdlist-entry__conveyor" :name='$t("champions-ui-box.champions.conveyor")' :variableName="'conveyor'"
        :uniqueRank=this.$store.state.conveyor.rank :percent=products[5].percentage :data-position=products[5].rank
        @mouseover.native="$emit('hoverEnter', 'conveyor', 1.2)" @mouseout.native="$emit('hoverOut', 'conveyor')"
        @mousedown.native="$emit('click', 'conveyor')">
            <template #icon>
                <ConveyorSVG/>
            </template>
        </HiddenChampinion>
        <HiddenChampinion class="cdlist-entry__lift" :name='$t("champions-ui-box.champions.lift")' :variableName="'lift'"
        :uniqueRank=this.$store.state.lift.rank :percent=products[2].percentage :data-position=products[2].rank
        @mouseover.native="$emit('hoverEnter', 'lift', 1.2)" @mouseout.native="$emit('hoverOut', 'lift')"
        @mousedown.native="$emit('click', 'lift')">
            <template #icon>
                <LiftSVG/>
            </template>
        </HiddenChampinion>
        <HiddenChampinion class="cdlist-entry__elevator" :name='$t("champions-ui-box.champions.elevator")' :variableName="'elevator'"
        :uniqueRank=this.$store.state.elevator.rank :percent=products[3].percentage :data-position=products[3].rank
        @mouseover.native="$emit('hoverEnter', 'elevator', 1.3)" @mouseout.native="$emit('hoverOut', 'elevator')"
        @mousedown.native="$emit('click', 'elevator')">
            <template #icon>
                <ElevatorSVG/>
            </template>
        </HiddenChampinion>
    </ul>
</template>

<script>
    import HiddenChampinion from '.././HiddenChampinion.vue'

    import CompressorSVG from '.././svg/components/CompressorSVG.vue'
    import ConveyorSVG from '.././svg/components/ConveyorSVG.vue'
    import ElevatorSVG from '.././svg/components/ElevatorSVG.vue'
    import FanSVG from '.././svg/components/FanSVG.vue'
    import LiftSVG from '.././svg/components/LiftSVG.vue'
    import PumpSVG from '.././svg/components/PumpSVG.vue'

    export default {
        name: 'ProductRanking',
        components: {
            HiddenChampinion,
            CompressorSVG,
            ConveyorSVG,
            ElevatorSVG,
            FanSVG,
            LiftSVG,
            PumpSVG
        },
        data() {
            return {
                timerStarted: false,
                products: [
                    {   
                        id: 1,
                        name: "pump",
                        percentage: 0,
                        rank: 6
                    },
                    {
                        id: 2,
                        name: "fan",
                        percentage: 0,
                        rank: 5
                    },
                    {
                        id: 3,
                        name: "lift",
                        percentage: 0,
                        rank: 0
                    },
                    {
                        id: 4,
                        name: "elevator",
                        percentage: 0,
                        rank: 3
                    },
                    {
                        id: 5,
                        name: "compressor",
                        percentage: 0,
                        rank: 2
                    },
                    {
                        id: 6,
                        name: "conveyor",
                        percentage: 0,
                        rank: 1
                    },
                ],
            }
        },
        computed: {
            pumpPercentage: function () {
                return this.$store.state.pump.energySavings;
            },
            fanPercentage: function () {
                return this.$store.state.fan.energySavings;
            },
            liftPercentage: function () {
                return this.$store.state.lift.energySavings;
            },
            elevatorPercentage: function () {
                return this.$store.state.elevator.energySavings;
            },
            compressorPercentage: function () {
                return this.$store.state.compressor.energySavings;
            },
            conveyorPercentage: function () {
                return this.$store.state.conveyor.energySavings;
            }
        },
        watch: {
            pumpPercentage(newVal){
                this.products[0].percentage = newVal; 
                if (newVal != 0){
                    this.startTimer();
                }
            },
            fanPercentage(newVal){
                this.products[1].percentage = newVal; 
                if (newVal  != 0){
                    this.startTimer();
                }
            },
            liftPercentage(newVal){
                this.products[2].percentage = newVal; 
                if (newVal  != 0){
                    this.startTimer();
                }
            },
            elevatorPercentage(newVal){
                this.products[3].percentage = newVal; 
                if (newVal != 0){
                    this.startTimer();
                }
            },
            compressorPercentage(newVal){
                this.products[4].percentage = newVal;
                if (newVal != 0){
                    this.startTimer();
                }
            },
            conveyorPercentage(newVal){
                this.products[5].percentage = newVal; 
                if (newVal != 0){
                    this.startTimer();
                }
            },
        },
        methods: {
            startTimer(){
                setTimeout(() => {
                    if (this.timerStarted === false){
                        this.timerStarted = true;
                        this.rankChildren();
                        setTimeout(() => {
                            this.sortChildren('#cdranking', 1);
                        }, 100);
                    }
                }, 500);
                this.timerStarted = false; 
            },
            rankChildren(){
                //copy list
                let listDupe = this.products.map((x) => x);

                //if percentage is 0 check old rank 
                for (let i = 0, len = listDupe.length; i < len; i++) {
                    if (listDupe[i].percentage === 0) {
                        listDupe[i].percentage = parseFloat(String(listDupe[i].percentage) + ".00" + (listDupe.length - listDupe[i].rank));
                    }
                }

                //sort after percentage 
                listDupe.sort(function(a,b) {return b.percentage - a.percentage});

                for (let i = 0, len = listDupe.length; i < len; i++) {
                    if (listDupe[i].percentage <= 0.01){
                        listDupe[i].percentage = 0;
                    }  
                    
                    if (listDupe[i].rank != i+1){
                        listDupe[i].rank = i+1;
                    }

                    let newUniqueRank = parseFloat(i+1 + "." + Math.floor(Date.now() * Math.random()));

                    this.$store.commit('setRank', {product: String(listDupe[i].name), rank: newUniqueRank})
                }

                //sort after id
                listDupe.sort(function(a,b) {return a.id - b.id});
                this.products = listDupe;
            },
            sortChildren(containerSelector, reverse) {
                const container = document.querySelector(containerSelector);
                const order = reverse ? -1 : 1;
                
                Array.from(container.children)
                    .sort((a, b) => order * parseInt(b.dataset.position, 10) - order * parseInt(a.dataset.position, 10) )
                    .forEach(element => container.appendChild(element));
            
                // Note you could also conditionally use Array.reverse() instead of the order variable.
            }
        }
    }
</script>

<style lang="scss">
    .cdgame{
        .cdranking{
            list-style-type: none;
            padding: 0;
            margin: 0;
            }
    }
    
</style>