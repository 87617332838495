<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4H5V5H4C2.34315 5 1 6.34315 1 8V15C1 16.6569 2.34315 18 4 18H19C19.5523 18 20 17.5523 20 17V16H21V13H23V10H21V7H20V6C20 5.44772 19.5523 5 19 5H8V4ZM3 6H14V7H3V6ZM14 8H3V9H14V8ZM14 10H3V11H14V10ZM14 12H3V13H14V12ZM14 14H3V15H14V14ZM3 16H14V17H3V16ZM19 6H15V17H19V6ZM24 20H0V22H24V20Z"/>
    </svg>
</template>

<script>
export default {
    name: 'MotorEfficiencySVG',
}
</script>
    