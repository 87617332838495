<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66 3.7498L6.05 4.1398C6.44 4.5198 6.44 5.1598 6.05 5.5398L6.04 5.5498C5.65 5.9398 5.03 5.9398 4.64 5.5498L4.25 5.1598C3.86 4.7798 3.86 4.1498 4.25 3.7598L4.26 3.7498C4.64 3.3698 5.27 3.3598 5.66 3.7498ZM1.99 10.4998H3.01C3.56 10.4998 4 10.9398 4 11.4998V11.5098C4 12.0598 3.56 12.4998 3 12.4898H1.99C1.44 12.4898 1 12.0498 1 11.4998V11.4898C1 10.9398 1.44 10.4998 1.99 10.4998ZM12 0.549805H12.01C12.56 0.549805 13 0.989805 13 1.5398V2.5098C13 3.0598 12.56 3.4998 12 3.4898H11.99C11.44 3.4898 11 3.0498 11 2.4998V1.5398C11 0.989805 11.44 0.549805 12 0.549805ZM18.34 3.7498C18.73 3.3698 19.36 3.3698 19.75 3.7598C20.14 4.1498 20.14 4.7698 19.75 5.1598L19.36 5.5498C18.98 5.9398 18.35 5.9398 17.96 5.5498L17.95 5.5398C17.56 5.1598 17.56 4.5298 17.95 4.1398L18.34 3.7498ZM20 11.4998V11.4898C20 10.9398 20.44 10.4998 20.99 10.4998H22C22.55 10.4998 22.99 10.9398 22.99 11.4898V11.4998C22.99 12.0498 22.55 12.4898 22 12.4898H20.99C20.44 12.4898 20 12.0498 20 11.4998ZM6 11.4998C6 8.1898 8.69 5.4998 12 5.4998C15.31 5.4998 18 8.1898 18 11.4998C18 11.6682 17.993 11.8351 17.9794 12C14.8435 12.0106 12.2742 14.4269 12.0206 17.4998C12.0137 17.4998 12.0069 17.4998 12 17.4998C8.69 17.4998 6 14.8098 6 11.4998ZM12.1966 19.529C12.1331 19.5164 12.0674 19.5098 12 19.5098H11.99C11.44 19.5098 11 19.9498 11 20.4998V21.4598C11 22.0098 11.44 22.4498 11.99 22.4498H12C12.55 22.4498 12.99 22.0098 12.99 21.4598V21.3027C12.6352 20.7656 12.3645 20.1681 12.1966 19.529ZM5.66 19.2398C5.27 19.6298 4.64 19.6298 4.25 19.2398C3.86 18.8498 3.86 18.2298 4.24 17.8298L4.63 17.4398C5.02 17.0498 5.65 17.0498 6.04 17.4398L6.05 17.4498C6.43 17.8298 6.44 18.4598 6.05 18.8498L5.66 19.2398Z"/>
        <path d="M15 19L18.0001 13V18H20.0001L17.0001 24L17 19H15Z"/>
    </svg>
</template>

<script>
export default {
    name: 'PhotovoltaicSVG',
}
</script>
    