<template>
    <div ref="tooltipContainer" class="cd-tooltip__container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <span ref="icon" class="cdtooltip__icon">
            <TooltipSVG/>
            <div ref="tooltip" class="cdtooltip__info-box">
                <div class="cdtooltip__info-box--inner">
                        <slot name="tooltip-info"></slot>
                </div>
                <div ref="arrow" class="cdtooltip__arrow-box">
                <span ref="arrowChild" class="cdtooltip__arrow"></span>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
    import TooltipSVG from '.././app1_components/svg/icons/TooltipSVG.vue'

    // @mouseenter="showTooltip($event, orientation)" @mouseleave="hideTooltip"

    export default {
        name: 'ToolTip',
        components: {
            TooltipSVG
        },
        data() {
            return {
                open: false
            }     
        },
        props: {
            orientation: {
            default: "center",
            type: String
            }
        },
        mounted() {
            window.addEventListener('touchend', this.checkTouch);
            window.addEventListener('resize', this.positionTooltip);
            window.addEventListener('load', this.positionTooltip);
            this.positionTooltip();
        },
        destroyed() {
            window.removeEventListener('touchend', this.checkTouch);
            window.removeEventListener('resize', this.positionTooltip);
            window.removeEventListener('load', this.positionTooltip);
        },
        computed: {
            rankingVisible() {
                return this.$store.state.ranking.activated;
            },
        },
        watch: {
            rankingVisible(){
                this.positionTooltip()
            },
            '$i18n.locale': function(newVal, oldVal) {
              setTimeout(()=> {
                if (this.tabVal !== null){
                    this.positionTooltip();
                }
          }
        ,100);
        }
        },
        methods: {
            positionTooltip(){
                let elemDiv = this.$refs.tooltip;
                let arrow = this.$refs.arrow;
                let arrowChild = this.$refs.arrowChild;
                elemDiv.style.transform = "translate(0px, 0px)";
                arrow.style.transform = "translate(0px, 0px)";
                arrowChild.style.marginRight = "0";

                let tooltip_rect = elemDiv.getBoundingClientRect();
                const padding = 0.5*16;


                //check if overlaps left
                if (tooltip_rect.x < 0) {
                    elemDiv.style.transform = "translate(" + String(parseInt(tooltip_rect.x*-1 + padding) +"px, 0px)"); 
                    arrow.style.transform = "translate(" + String(parseInt((tooltip_rect.x*-1 + padding)*-1) +"px, 0px)"); 
                }  

                //check if overlaps right
                else if ((tooltip_rect.x + tooltip_rect.width) > window.innerWidth) {
                    const value = window.innerWidth - (tooltip_rect.x + tooltip_rect.width);
                    elemDiv.style.transform = "translate(" + String(parseInt(value-padding) +"px, 0px)"); 
                    arrow.style.transform = "translate(" + String(parseInt((value-padding)*-1) +"px, 0px)"); 
                }

                //reposition arrow
                if (Math.abs(arrow.getBoundingClientRect().x + arrow.getBoundingClientRect().width - window.innerWidth) < 16){
                    arrowChild.style.marginRight = "2rem";
                }
                
                
            },
            checkMouse(event, type, orientation){

                if (matchMedia('(pointer:fine)').matches) {
                    if (type === "show"){
                        this.showTooltip(event, orientation)
                    }
                    else if (type === "hide"){
                        this.hideTooltip;
                    }
                }
            },
            showTooltip(){
                this.open = true;
                let elemDiv = this.$refs.tooltip;
                let tooltipCenter = this.$refs.icon.getBoundingClientRect().top + this.$refs.icon.getBoundingClientRect().height/2;
                let tooltopBoxHeight = elemDiv.getBoundingClientRect().height;
                let windowCenter = window.innerHeight/2

                if (tooltipCenter <= windowCenter){
                    elemDiv.classList.remove("cd-tooltip__info-box--top");
                    elemDiv.classList.add("cd-tooltip__info-box--bottom");
                    elemDiv.style.top = tooltipCenter + "px";
                }

                else if (tooltipCenter > windowCenter){
                    elemDiv.classList.remove("cd-tooltip__info-box--bottom");
                    elemDiv.classList.add("cd-tooltip__info-box--top");
                    elemDiv.style.top = String(tooltipCenter - tooltopBoxHeight) + "px";
                }
                
                elemDiv.style.opacity = "100";
                elemDiv.style.pointerEvents = "all";

                window.addEventListener('scroll', this.hideTooltip);

            },
            hideTooltip(){
                let elemDiv = this.$refs.tooltip;
                if (this.open === true){
                    this.open = false;
                    elemDiv.style.opacity = "0";
                    elemDiv.style.pointerEvents = "none";
                }
                window.removeEventListener('scroll', this.hideTooltip);
            },
            checkTouch(event){
                if (this.open === true && !this.$el.contains(event.srcElement)){
                    this.hideTooltip();
                }
                else if (this.open === false && this.$el.contains(event.srcElement)) {
                    this.showTooltip();
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .cdgame{

    .cd-tooltip__container{
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .cdtooltip__icon {
        /* margin-left: 0.5rem; */
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &:after{
            content: "";
            position: absolute;
            width: 32px;
            height: 32px;
            transform: translate(0, -8px);
        }
    }
    .cdtooltip__info-box{
        z-index: 10;
        display: flex;
        align-items: center;
        position: fixed;
        width: 500px;
        max-width: calc(100vw - 1rem);
        opacity: 0;
        transition: opacity 0.15s ease-in-out; 
        padding-bottom: 0rem;
        pointer-events: none;
    }

    .cd-tooltip__info-box--top{
        flex-direction: column;
    }

    .cd-tooltip__info-box--bottom{
        flex-direction: column-reverse;
    }

    .cdtooltip__info-box--inner{
        background-color: #484848;
        color: white; 
        text-align: left;
        border-radius: 0.25rem;
        box-shadow: 0px 8px 24px 0px #00000033;
        font-size: 1rem;
        line-height: 5;
        font-weight: 500;
        word-spacing: normal;
        padding: 0.75rem 1rem 0.875rem 1rem;
        z-index: 10;

    }

    .cdtooltip__arrow-box{
        z-index: 10;
        transform: translate(20px);
    }
    .cdtooltip__arrow{
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.25rem;
        background-color: #484848;
        transform-origin: 0.75rem 0.75rem;
        transform: translate(0, -60%) rotate(45deg);
    }

    .cd-tooltip__info-box--bottom .cdtooltip__arrow{
        transform: translate(0, 60%) rotate(45deg);
    }

    .cdtooltip__info-box a{
        color: white;
    }


    .cdtooltip__info-box p:first-child {
        margin-top: 0;
    }

    .cdtooltip__info-box p:last-child {
        margin-bottom: 0;
    }

    .cdgame__tooltip--margin{
        margin-bottom: 0.5rem; 
    }

    }

</style>