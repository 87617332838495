<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_430_19337)">
        <rect width="32" height="32" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8314 27.7011L11.1886 28.087L11.1955 28.0946L11.454 28.3739L12.2919 28.9636L13.3128 29.4475L14.4776 29.8071L15.7415 30.0285L17.0558 30.1033L17.9562 30.0521L18.3702 30.0285L19.634 29.8071L20.7988 29.4475L21.8198 28.9636L22.6577 28.3739L23.2802 27.7011L23.6636 26.9712L23.7044 26.732L23.7931 26.212V9.4752L23.6636 10.2343L23.2802 10.9643L22.6577 11.637L21.8198 12.2267L20.7988 12.7107L19.634 13.0703L18.3702 13.2917L17.0558 13.3664L15.7415 13.2917L14.4776 13.0703L13.3128 12.7107L12.2919 12.2267L11.454 11.637L10.8314 10.9643L10.4481 10.2343L10.3186 9.4752V15.2848V17.1969V17.2003V18.7238V21.831V25.8471V26.212L10.4481 26.9712L10.8314 27.7011ZM23.6636 14.5329L23.2802 15.2628L22.6577 15.9356L21.8198 16.5253L20.7988 17.0092L19.634 17.3688L18.3702 17.5902L17.0558 17.665L15.7415 17.5902L14.4776 17.3688V23.0106L15.7415 23.232L17.0558 23.3068L18.3702 23.232L19.634 23.0106L20.7988 22.651L21.8198 22.1671L22.6577 21.5774L23.2802 20.9047L23.6636 20.1747V14.5329Z" fill="#4E4E4E"/>
        <path d="M23.2802 15.2628L23.6636 14.5329V20.1747L23.2802 20.9047L22.6577 21.5774L21.8198 22.1671L20.7988 22.651L19.634 23.0106L18.3702 23.232L17.0558 23.3068L15.7415 23.232L14.4776 23.0106V17.3688L15.7415 17.5902L17.0558 17.665L18.3702 17.5902L19.634 17.3688L20.7988 17.0092L21.8198 16.5253L22.6577 15.9356L23.2802 15.2628Z" fill="#E6E6E6"/>
        <path d="M8.38039 15.5763L8.33035 15.5608L8.33187 16.4494L8.62772 17.4036L9.3058 18.1369L10.3186 18.7238V17.2003V17.1969L10.2435 17.1537L9.81466 16.718L9.65196 16.1286L9.65307 15.5774L9.34602 15.6312L9.01993 15.6498L8.69391 15.6312L8.38039 15.5763Z" fill="#BEBEBF"/>
        <path d="M10.4481 8.71602L10.6912 8.25297V6.39415L10.6591 6.58247L10.564 6.76359L10.4096 6.93046L10.2017 7.07671L9.9485 7.19677L9.65953 7.28596L9.34602 7.34092L9.01993 7.35948L8.69391 7.34092L8.38039 7.28596L8.09142 7.19677L7.83815 7.07671L7.63035 6.93046L7.4759 6.76359L7.38075 6.58247L7.34863 6.39415V10.5393V14.6845L7.38075 14.8728L7.4759 15.0539L7.63035 15.2207L7.83815 15.3671L8.09142 15.4871L8.33035 15.5608L8.38039 15.5763L8.69391 15.6312L9.01993 15.6498L9.34602 15.6312L9.65307 15.5774L9.65953 15.5763L9.9485 15.4871L10.2017 15.3671L10.3186 15.2848V9.4752L10.4481 8.71602Z" fill="#4E4E4E"/>
        <path d="M10.6912 6.39415L10.6591 6.20583L10.564 6.02476L10.4096 5.85784L10.2017 5.71159L9.9485 5.59153L9.88547 5.57207V6.39415L9.86886 6.4917L9.8196 6.58547L9.73959 6.67187L9.63199 6.74766L9.50083 6.80982L9.35117 6.85604L9.18878 6.88444L9.01993 6.89406L8.85109 6.88444L8.68876 6.85604L8.53909 6.80982L8.40794 6.74766L8.30028 6.67187L8.22032 6.58547L8.17106 6.4917L8.15445 6.39415V5.57207L8.09142 5.59153L7.83815 5.71159L7.63035 5.85784L7.4759 6.02476L7.38075 6.20583L7.34863 6.39415L7.38075 6.58247L7.4759 6.76359L7.63035 6.93046L7.83815 7.07671L8.09142 7.19677L8.38039 7.28596L8.69391 7.34092L9.01993 7.35948L9.34602 7.34092L9.65953 7.28596L9.9485 7.19677L10.2017 7.07671L10.4096 6.93046L10.564 6.76359L10.6591 6.58247L10.6912 6.39415Z" fill="#686868"/>
        <path d="M9.86886 6.4917L9.88547 6.39415V5.57207V3.40318L9.86886 3.50074L9.8196 3.5945L9.73959 3.68096L9.63199 3.75669L9.50083 3.81885L9.35117 3.86507L9.18878 3.89353L9.01993 3.9031L8.85109 3.89353L8.68876 3.86507L8.53909 3.81885L8.40794 3.75669L8.30028 3.68096L8.22032 3.5945L8.17106 3.50074L8.15445 3.40318V5.57207V6.39415L8.17106 6.4917L8.22032 6.58547L8.30028 6.67187L8.40794 6.74766L8.53909 6.80982L8.68876 6.85604L8.85109 6.88444L9.01993 6.89406L9.18878 6.88444L9.35117 6.85604L9.50083 6.80982L9.63199 6.74766L9.73959 6.67187L9.8196 6.58547L9.86886 6.4917Z" fill="#BEBEBF"/>
        <path d="M10.6912 8.25297L10.4481 8.71602L10.3186 9.4752L10.4481 10.2343L10.8314 10.9643L11.454 11.637L12.2919 12.2267L13.3128 12.7107L14.4776 13.0703L15.7415 13.2917L17.0558 13.3664L18.3702 13.2917L19.634 13.0703L20.7988 12.7107L21.8198 12.2267L22.6577 11.637L23.2802 10.9643L23.6636 10.2343L23.7931 9.4752L23.6636 8.71602L23.2802 7.98608L22.6577 7.31331L21.8198 6.72362L20.796 6.09255L21.4489 6.55202L21.9341 7.07629L22.2328 7.64509L22.3337 8.23667L22.2328 8.82825L21.9341 9.39705L21.4489 9.92132L20.796 10.3808L20.0004 10.7579L19.0927 11.0382L18.1079 11.2107L17.0837 11.269L16.0594 11.2107L15.0746 11.0382L14.1669 10.7579L13.3714 10.3808L12.7185 9.92132L12.2333 9.39705L11.9346 8.82825L11.8337 8.23667L11.9346 7.64509L12.2333 7.07629L12.7185 6.55202L13.3714 6.09255L12.2919 6.72362L11.454 7.31331L10.8314 7.98608L10.6912 8.25297Z" fill="#5A5A5A"/>
        <path d="M19.0927 5.43524L18.6935 5.36529L18.7059 5.43797V8.23667L18.6748 8.41947L18.5825 8.59522L18.4325 8.75725L18.2308 8.89924L17.985 9.01573C17.985 9.01573 17.8159 9.0753 17.7045 9.10234C17.5872 9.1308 17.4002 9.15567 17.4002 9.15567L17.0837 9.17365L16.7672 9.15567L16.4629 9.10234L16.1824 9.01573L15.9365 8.89924L15.7348 8.75725L15.5849 8.59522L15.4926 8.41947L15.4614 8.23667V5.43797L15.4738 5.36529L15.0746 5.43524L14.1669 5.71543L13.3714 6.09255L12.7185 6.55202L12.2333 7.07629L11.9346 7.64509L11.8337 8.23667L11.9346 8.82825L12.2333 9.39705L12.7185 9.92132L13.3714 10.3808L14.1669 10.7579L15.0746 11.0382L16.0594 11.2107L17.0837 11.269L18.1079 11.2107L19.0927 11.0382L20.0004 10.7579L20.796 10.3808L21.4489 9.92132L21.9341 9.39705L22.2328 8.82825L22.3337 8.23667L22.2328 7.64509L21.9341 7.07629L21.4489 6.55202L20.796 6.09255L20.0004 5.71543L19.0927 5.43524Z" fill="#686868"/>
        <path d="M18.6748 8.41947L18.7059 8.23667V5.43797L18.6748 5.62077L18.5825 5.79652L18.4325 5.9585L18.2308 6.10049L17.985 6.21703L17.7045 6.30364L17.4002 6.35697L17.0837 6.37495L16.7672 6.35697L16.4629 6.30364L16.1824 6.21703L15.9365 6.10049L15.7348 5.9585L15.5849 5.79652L15.4926 5.62077L15.4614 5.43797V8.23667L15.4926 8.41947L15.5849 8.59522L15.7348 8.75725L15.9365 8.89924L16.1824 9.01573L16.4629 9.10234L16.7672 9.15567L17.0837 9.17365L17.4002 9.15567C17.4002 9.15567 17.5872 9.1308 17.7045 9.10234C17.8159 9.0753 17.985 9.01573 17.985 9.01573L18.2308 8.89924L18.4325 8.75725L18.5825 8.59522L18.6748 8.41947Z" fill="#BEBEBF"/>
        <path d="M18.6748 5.62077L18.7059 5.43797L18.6935 5.36529L18.6748 5.25517L18.5825 5.07942L18.4325 4.91739L18.2308 4.7754L17.985 4.65891L17.7045 4.5723L17.4002 4.51897L17.0837 4.50099L16.7672 4.51897L16.4629 4.5723L16.1824 4.65891L15.9365 4.7754L15.7348 4.91739L15.5849 5.07942L15.4926 5.25517L15.4738 5.36529L15.4614 5.43797L15.4926 5.62077L15.5849 5.79652L15.7348 5.9585L15.9365 6.10049L16.1824 6.21703L16.4629 6.30364L16.7672 6.35697L17.0837 6.37495L17.4002 6.35697L17.7045 6.30364L17.985 6.21703L18.2308 6.10049L18.4325 5.9585L18.5825 5.79652L18.6748 5.62077Z" fill="#E6E6E6"/>
        <path d="M9.88547 3.40318L9.86886 3.30568L9.8196 3.21192L9.73959 3.12546L9.63199 3.04973L9.50083 2.98757L9.35117 2.94134L9.18878 2.91289L9.01993 2.90332L8.85109 2.91289L8.68876 2.94134L8.53909 2.98757L8.40794 3.04973L8.30028 3.12546L8.22032 3.21192L8.17106 3.30568L8.15445 3.40318L8.17106 3.50074L8.22032 3.5945L8.30028 3.68096L8.40794 3.75669L8.53909 3.81885L8.68876 3.86507L8.85109 3.89353L9.01993 3.9031L9.18878 3.89353L9.35117 3.86507L9.50083 3.81885L9.63199 3.75669L9.73959 3.68096L9.8196 3.5945L9.86886 3.50074L9.88547 3.40318Z" fill="#E6E6E6"/>
        </g>
        <defs>
        <clipPath id="clip0_430_19337">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'CompressorSVG'
    }
</script>