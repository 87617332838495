<template>
    <div class="cdinput__toggle" :class="{ 'cdinput__toggle--on': isActive }" @click="toggleIf($event, isActive, false)" v-bind:style= "[canBeActivated ? {'cursor': 'pointer'} : {'cursor': 'auto'}]">
      <div class="cdinput__toggle-content">
        <div class="cdinput__prompt-box" :class="[{ 'cdinput__prompt-box--visible': canBeActivated }]">
          <span class="cdinput__prompt" v-if="!this.$store.state.activated"> {{ $t("toggle-prompt.initial") }}</span>
          <span class="cdinput__prompt" v-if="this.$store.state.activated" :class="(this.$store.state.activated)?'cdvisible':'cdinvisible'">{{ $t("toggle-prompt.general") }}</span>
          <!-- <ArrowSVG />
          <ArrowSVG />
          <ArrowSVG /> -->
        </div>
        <div class="cdinput__toggle-content-inner">
            <div ref="toggleKnob" class="cdinput__toggle-knob" :class="{ 'cdinput__pulse': (shouldPulse === true && wasActivated === false) }">
              <slot name="knob"></slot>
              <slot name="knob"></slot>
            </div>
          <slot name="input" :isActive="isActive"></slot>
        </div>
      </div>

      <!-- @click="toggleIf($event, isActive, false) -->
      
      <!-- <div class="cdinput__toggle-knob">
          <span>{{ msg }}</span>
        </div> -->
    </div>
  </template>
  
  <script>
  import ArrowSVG from '.././app1_components/svg/icons/ArrowSVG.vue';

  export default {
    name: 'InputToggle',
    components: { ArrowSVG },
    data() {
      return {
        isActive: false,
        wasActivated: false
      }
    },
    props: {
      msg: String,
      canBeActivated: {
        default: true,
          type: Boolean
      },
      shouldPulse: {
        default: false, 
        type: Boolean
      }
    },
    mounted() {
    },
    watch: {
      isActive(newVal) {
        if (newVal === true){
          this.wasActivated = true;
        }
      },
    },
    methods: {
      toggle(){
        if (this.canBeActivated){
          this.isActive = !this.isActive;
          this.$emit('toggleInput');
        }
      },
      toggleIf(e, active, test){
        if (active === test){
          this.toggle();
        } 
        else if (active !== test && this.$refs.toggleKnob.contains(e.target)){
          this.toggle();
        }
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
    .cdgame{


  .cdinput__toggle{
    display: flex;
    align-items: center;
    background-color: var(--cd-background-middle-grey);
    border-radius: 0.25rem;
    border: 1px solid var(--cd-background-middle-grey);
    height: 3.5rem;
    position: relative;
    overflow: hidden;
  }


  .cdinput__toggle-content{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 3px;
  }

  .cdinput__toggle-content-inner{
    padding: 0.3125rem 3.5rem 0.3125rem 0.3125rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0px 3px 3px 0px;
    background-color:  var(--cd-text-color-light);
    transform: translate(calc(-100% + 3rem + 2px) , 0);
    transition: all 0.5s cubic-bezier(0.43, 1.1, 0.86, 1);
  }

  .cdinput__toggle-knob{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px 0px #00000024;
    color: #818283;
    color: rgba(0, 0, 0, 0.15);
    background: var(--cd-text-color-light);
    border-radius: 0.2rem;
    border: 1px solid var(--cd-background-middle-grey);
    height: calc(100% - 2px);
    width: 3rem;
    right: 0px;
    position: absolute;
    z-index: 3;
    transition: all 0.5s cubic-bezier(0.43, 1.1, 0.86, 1);
  }

  .cdinput__toggle-knob > svg{
    position: absolute;
  }

  .cdinput__toggle-knob > svg:nth-child(1)  {
    fill: var(--cd-color-danfoss-red);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .cdinput__toggle-knob.cdinput__pulse > svg:nth-child(1)  {
    animation: pulse 1.5s infinite;
  }



  .cdinput__toggle-knob > svg:nth-child(2)  {
    fill: var(--cd-text-color-primary);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .cdinput__toggle--on .cdinput__toggle-knob > svg:nth-child(1) {
	  opacity: 0;
  }

  .cdinput__toggle--on .cdinput__toggle-knob > svg:nth-child(2) {
	  opacity: 1;
  }


  @keyframes pulse {
	0% {
		transform: scale(1);
  }
  50% {
		transform: scale(1.3);
	}
  55% {
		transform: scale(1.3);
	}
  100% {
		transform: scale(1);
	}
  }

  .cdinput__toggle--on .cdinput__toggle-knob {
    right: 1px;
    border: 0px solid var(--cd-background-middle-grey);
  }

  .cdinput__toggle--on .cdinput__toggle-content-inner {
    animation: 0.5s ease-in-out 0s 1 normal forwards running toggle-content;
    transform: translate(0%);
  }


  @media (pointer: fine) {
    .cdinput__toggle:hover .cdinput__prompt{
    color: var(--cd-text-color-primary);
  }
  }

  .cdinput__toggle .cdinput__prompt{
    font-family: Myriad-Pro-Semi!important;
    line-height: 1!important;
    color: var(--cd-text-color-secondary);
    // font-style: italic;
    margin-bottom: -3px;
    transition: color 0.3s ease-in-out;
    animation: fadeIn 0.3s; 
  }


    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  .cdinput__prompt--visible{
    color: var(--cd-text-color-secondary);
    transition: color 3s ease-in-out;
  }

  .cdinput__prompt-box{
    position: absolute;
    margin-left: 4.375rem;
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    // transition: opacity 3s ease-in-out;
    }
   .cdinput__prompt-box--visible{
    opacity: 1;
   }
  }
  </style>
  