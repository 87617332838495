<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C4.89543 0 4 0.89543 4 2V22C4 23.1046 4.89543 24 6 24H18C19.1046 24 20 23.1046 20 22V2C20 0.895431 19.1046 0 18 0H6ZM9 2H6V22H9V2ZM10 3.5C10 3.22386 10.2239 3 10.5 3H13.5C13.7761 3 14 3.22386 14 3.5V5.5C14 5.77614 13.7761 6 13.5 6H10.5C10.2239 6 10 5.77614 10 5.5V3.5ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9ZM15 2H18V22H15V2Z" />
    </svg>
</template>

<script>
export default {
    name: 'ControlSVG'
}
</script>
    