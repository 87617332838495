<template>
  <div :class="($store.state.internetConnection === true && rankingVisible === true)?'main-content hover-effects':'main-content'">
    <AppHeader />
    <PWAPrompt />
  <div class="app-parent align-items-center">
    <!-- d-flex -->
    <div id="app" class="cdgame container-lg" >
    <StartScreenOverlay ref="startscreen"/>
    
    <transition name="fade-in" appear>
      <div v-if="showFeedback" class="cdgame__popup-overlay" ref="overlay">
        <div class="cdgame__popup" ref="feedback">
          <div>
            <span class="cdgame__popup-close" @click="showFeedback = false">
              ✕
            </span>
            <h2 class="cdgame__h2">
              {{ $t("popup.heading") }}
            </h2>
            <i18n v-if="$store.state.internetConnection === true" 
              path="popup.online.text" tag="p"
            >
              <template v-slot:link-text>
                <a
                class="cdgame__link" 
                          :href='$t("popup.online.link")' 
                          target=”_blank”
                >{{ $t("popup.online.link-text") }}</a>
              </template>
            </i18n>
            <p v-else="$store.state.internetConnection === false">
              {{ $t("popup.offline.text") }}
            </p>
            <div class="cdgame__qr-code-container" v-if="$store.state.internetConnection === false">
              <img v-if="$i18n.locale === 'de'" src="./assets/qr-codes/Gamification-main-page-german.png" alt="QR Code, der zur Danfoss Discover Drives Website mit Registrierungsformular führt">
              <img v-else="$i18n.locale === 'en'" src="./assets/qr-codes/Gamification-main-page-english.png" alt="QR Code leading to Danfoss Discover Drives webpage with contact form">
            </div>
            <button class="cdgame__button"
              @click="showFeedback = false">
              {{ $t("popup.button") }}
            </button>   
          </div>
        </div>
      </div>
    </transition>
    <div class="cdgame__box">
      <div class="cdgame__progress-bars">
          <RadialProgressBar :percentage="this.totalEmissionSavings">
            <template #icon>
                <EmissionSVG />
            </template>
            <template #name>
              <div class="cdgame__tooltip">
                <h5 class="cdgame__h5">
                  <i18n path="emission-savings.heading">
                    <template v-slot:0>&shy;</template>
                  </i18n>
                </h5>
                <ToolTip :orientation="'center'">
                  <template #tooltip-info>
                    <i18n path="emission-savings.tooltip-text.text" tag="p" class="cdgame__text">
                      <template v-slot:0><b>{{ $t("emission-savings.tooltip-text.0") }}</b></template>
                    </i18n>
                  </template>
                </ToolTip>
              </div>
            </template>
          </RadialProgressBar>
          <RadialProgressBar :name="'Energieeinsparung'" :percentage="this.totalEnergySavings">
            <template #icon>
                <EnergySVG />
            </template>
            <template #name>
              <div class="cdgame__tooltip">
                <h5 class="cdgame__h5">
                  <i18n path="energy-savings.heading">
                      <template v-slot:0>&shy;</template>
                  </i18n>
                </h5>
                <ToolTip :orientation="'center'">
                  <template #tooltip-info>
                    <i18n path="energy-savings.tooltip-text.text" tag="p" class="cdgame__text">
                      <template v-slot:0><b>{{ $t("energy-savings.tooltip-text.0") }}</b></template>
                      <!-- (this.$store.state.energySources.photovoltaic.value >= 1)?'cdvisible':'cdinvisible' -->
                      <template v-slot:link-text>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("energy-savings.tooltip-text.link")' 
                          target=”_blank”
                        >
                          {{ $t("energy-savings.tooltip-text.link-text") }}</a>
                        <span v-else>
                          {{ $t("energy-savings.tooltip-text.link-text") }}</span>
                      </template>
                    
                    </i18n>
                  </template>
                </ToolTip>
              </div>
            </template>
          </RadialProgressBar>
      </div>
      <div class="cdgame__room">
        <RoomSVG @hoverEnter="hoverEnter" @hoverOut="hoverOut" @click="click"/>
        <div class="cdgame__regenerative-energy--solar">
          <div class="cdgame__solar-panel-svg">
            <SolarPanelSVG :class="(this.$store.state.energySources.photovoltaic.value >= 1)?'cdvisible':'cdinvisible'"/>
            <SolarPanelSVG :class="(this.$store.state.energySources.photovoltaic.value >= 2)?'cdvisible':'cdinvisible'"/>
            <SolarPanelSVG :class="(this.$store.state.energySources.photovoltaic.value >= 3)?'cdvisible':'cdinvisible'"/>
          </div>
        </div>
        <div class="cdgame__regenerative-energy--wind">
          <div class="cdgame__wind-turbine-svg">
            <WindTurbineSVG :option="1" :class="(this.$store.state.energySources.greenEnergy.value >= 1)?'cdvisible':'cdinvisible'"/>
            <WindTurbineSVG :option="2" :class="(this.$store.state.energySources.greenEnergy.value >= 2)?'cdvisible':'cdinvisible'"/>
            <WindTurbineSVG :option="3" :class="(this.$store.state.energySources.greenEnergy.value >= 3)?'cdvisible':'cdinvisible'"/>
          </div>
        </div>
      </div>
      <div :style='{ "margin-top": "2rem"}' >
          <button class="cdgame__button" id="cdgame__button--feedback"
            :disabled=!this.$store.state.feedback.activated
            :class="[{ 'cdgame__button--inactive': this.$store.state.feedback.activated === false }, 
            (this.$store.state.feedback.isVisible === true)?'cdvisible':'cdinvisible']"
            @click="showFeedback = true">
            {{ $t("auswertung-button") }}
        </button>   
      </div>
    </div>
    <div class="cdgame__menu">
      <div id="cdgame__scrollable-box" ref="scrollableBox">
        <div class="cdgame__box cdgame__box--ui">
          <div class="cdgame__tooltip">
              <h3 class="cdgame__h3">{{ $t("energy-ui-box.heading") }}</h3>
              <ToolTip :orientation="'center'">
                <template #tooltip-info>
                  <p>{{ $t("energy-ui-box.tooltip-text") }}</p>
                </template>
              </ToolTip>
            </div>
          <div>
            <h4 class="cdgame__h4"><span>{{ $t("energy-ui-box.photovoltaic.heading") }}</span><span class="cdgame__percent"> %</span></h4>
            <PhotovoltaicToggleInput />
          </div>
          <div>
            <h4 class="cdgame__h4"><span>{{ $t("energy-ui-box.green-energy.heading") }}</span><span class="cdgame__percent"> %</span></h4>
            <GreenEnergyToggleInput />
          </div>
        </div>
        <div id="cdgame__box--drive-system" class="cdgame__box cdgame__box--ui"  v-if="this.driveSystem === false">
          <div class="cdgame__tooltip">
            <h3 class="cdgame__h3">{{ $t("drive-system-explore-box.heading") }}</h3>
            <ToolTip :orientation="'center'">
                  <template #tooltip-info>
                    <i18n path="drive-system-explore-box.tooltip-text.text" tag="p" class="cdgame__text">
                      <template v-slot:link-text>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("drive-system-explore-box.tooltip-text.link")' 
                          target=”_blank”
                        >
                          {{ $t("drive-system-explore-box.tooltip-text.link-text") }}</a>
                        <span v-else>
                          {{ $t("drive-system-explore-box.tooltip-text.link-text") }}</span>
                      </template>
                    </i18n>
                  </template>
            </ToolTip>
          </div>
          <div>
            <p>{{ $t("drive-system-explore-box.description.text") }}</p>
            <button class="cdgame__button cdgame__button--center btn" 
            :disabled=!this.$store.state.driveSystem.activated
            :class="(this.$store.state.driveSystem.activated === true)?'':'cdgame__button--inactive'"
            @click="$store.commit('activateOption', {kind: 'room', activated: true }), $store.commit('activateOption', {kind: 'feedback-button', activated: true })">{{ $t("drive-system-explore-box.button") }}</button>   
          </div>
        </div>
        <div class="cdgame__box cdgame__box--ui" v-else>
          <div class="cdgame__tooltip">
            <h3 class="cdgame__h3">{{ $t("drive-system-ui-box.heading") }}</h3>
            <ToolTip :orientation="'center'">
                  <template #tooltip-info>
                    <i18n path="drive-system-ui-box.tooltip-text.text" tag="p" class="cdgame__text">
                      <template v-slot:link-text-1>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("drive-system-ui-box.tooltip-text.link-1")' 
                          target=”_blank”
                        >
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-1") }}</a>
                        <span v-else>
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-1") }}</span>
                      </template>
                      
                      <template v-slot:link-text-2>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("drive-system-ui-box.tooltip-text.link-2")' 
                          target=”_blank”
                        >
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-2") }}</a>
                        <span v-else>
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-2") }}</span>
                      </template>

                      <template v-slot:link-text-3>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("drive-system-ui-box.tooltip-text.link-3")' 
                          target=”_blank”
                        >
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-3") }}</a>
                        <span v-else>
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-3") }}</span>
                      </template>

                      <template v-slot:link-text-4>
                        <a v-if="$store.state.internetConnection === true"
                          class="cdgame__link" 
                          :href='$t("drive-system-ui-box.tooltip-text.link-4")' 
                          target=”_blank”
                        >
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-4") }}</a>
                        <span v-else>
                          {{ $t("drive-system-ui-box.tooltip-text.link-text-4") }}</span>
                      </template>
                      
                     </i18n>
                  </template>
            </ToolTip>
          </div>
          <div>
            <div>
              <h4 class="cdgame__h4">{{ $t("drive-system-ui-box.motor-efficiency.heading") }}</h4>
              <MotorEfficiencyToggleInput />
            </div>
            <div>
              <h4 class="cdgame__h4">{{ $t("drive-system-ui-box.control.heading") }}</h4>
              <ControlToggleInput />
            </div>
            <div>
              <h4 class="cdgame__h4">{{ $t("drive-system-ui-box.regenerative-energy.heading") }}</h4>
              <RegenerativeEnergyToggleInput />
            </div>  
          </div>  
        </div>
        <div ref="test" class="cdgame__box cdgame__box--ui" 
        v-show="rankingVisible === true">
        <div class="cdgame__tooltip">
              <h4 class="cdgame__h4 cdgame__h4--no-margin">{{ $t("champions-ui-box.heading") }}</h4>
              <ToolTip :orientation="'center'" class="cdgame__tooltip--margin">
                <template #tooltip-info>
                    <i18n path="champions-ui-box.tooltip-text.text" tag="p" class="cdgame__text">
                      <template v-slot:0>
                        <i18n path="champions-ui-box.tooltip-text.0" tag="b">
                          <template v-slot:link-text-1>
                            <a v-if="$store.state.internetConnection === true"
                              class="cdgame__link" 
                              :href='$t("champions-ui-box.tooltip-text.link-1")' 
                              target=”_blank”
                            >
                            {{ $t("champions-ui-box.tooltip-text.link-text-1") }}</a>
                            <span v-else>
                              {{ $t("drive-system-ui-box.tooltip-text.link-text-4") }}</span>
                          </template>
                        </i18n>
                      </template>
                     </i18n>
                  </template>
              </ToolTip>
            </div>

          <ProductRanking @hoverEnter="hoverEnter" @hoverOut="hoverOut" @click="click"/>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>  
</template>

<script>

import AppHeader from './app1_components/layout/AppHeader.vue'
import PWAPrompt from './app1_components/PWAPrompt.vue'
import StartScreenOverlay from './app1_components/layout/StartScreenOverlay.vue'
import PhotovoltaicToggleInput from './app1_components/layout/PhotovoltaicToggleInput.vue'
import GreenEnergyToggleInput from './app1_components/layout/GreenEnergyToggleInput.vue'
import MotorEfficiencyToggleInput from './app1_components/layout/MotorEfficiencyToggleInput.vue'
import ControlToggleInput from './app1_components/layout/ControlToggleInput.vue'
import RegenerativeEnergyToggleInput from './app1_components/layout/RegenerativeEnergyToggleInput.vue'
import ProductRanking from './app1_components/layout/ProductRanking.vue'
import SolarPanelSVG from './app1_components/svg/SolarPanelSVG.vue'
import WindTurbineSVG from './app1_components/svg/WindTurbineSVG.vue'
import RoomSVG from './app1_components/svg/RoomSVG.vue'
import EmissionSVG from './app1_components/svg/icons/EmissionSVG.vue'
import EnergySVG from './app1_components/svg/icons/EnergySVG.vue'
import RadialProgressBar from './app1_components/RadialProgressBar.vue'
import ToolTip from './app1_components/ToolTip.vue'


import { calculations } from './mixins/calculations.js'

export default {
  name: 'App',
  mixins: [calculations],
  components: {
    // SVG Elements
    EmissionSVG,
    EnergySVG,
    RadialProgressBar,
    SolarPanelSVG,
    RoomSVG,
    WindTurbineSVG,

    // Toggle Inputs
    ControlToggleInput,
    GreenEnergyToggleInput,
    MotorEfficiencyToggleInput,
    PhotovoltaicToggleInput,
    RegenerativeEnergyToggleInput,

    // Other Components
    AppHeader,
    PWAPrompt,
    ProductRanking,
    StartScreenOverlay,
    ToolTip,
  },
  onIdle() {
    if (this.$refs.startscreen.show === false){
      location.reload();
    }
  },
  onActive() {
  },
  data() {
    return {
      wpData: typeof wpData === 'undefined' ? { danfossLang: 'default', } : wpData, 
      showFeedback: false,
      showStartscreen: false
    }
  },
  mounted() {
    this.handleConnection();
    if (this.storageAvailable("localStorage")) {
      if (!localStorage.getItem("locale")) {
        this.populateStorage(this.$i18n.locale);
      } else {
        this.setLanguage();
      }
    } 
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);
  },
  destroyed() {
      window.removeEventListener('online', this.handleConnection);
      window.removeEventListener('offline', this.handleConnection);
  },
  computed: {
    driveSystem() {
      return this.$store.state.driveSystem.isVisible;
    },
    rankingVisible() {
      return this.$store.state.ranking.activated;
    },
    totalEnergySavings(){
      return this.$store.state.energySavingsTotal;
    },
    totalEmissionSavings(){
      return this.$store.state.emissionSavingsTotal;
    }
  },
  watch: {
    rankingVisible(newVal){
      if (newVal === true){
        setTimeout(() => 
        this.showRanking(),
         1000);
      }
    },
    '$i18n.locale'(newVal) {
      this.populateStorage(newVal);
      }
  },
  methods: {
    handleConnection() {
      if (navigator.onLine) {
        this.isReachable(this.getServerUrl()).then((online) => {
          if (online) {
            // handle online status
            this.$store.commit('setConnection', { value: true });
          } else {
            this.$store.commit('setConnection', { value: false })
          }
        });
      } else {
        // handle offline status
        this.$store.commit('setConnection', { value: false });
      }
    },
    isReachable(url) {
      return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then((resp) => resp && (resp.ok || resp.type === 'opaque'))
        .catch((err) => {
          console.warn(`[conn test failure]: ${err}`);
        });
    },
    getServerUrl() {
      return window.location.origin;
    },
    showRanking(){
      this.$refs.scrollableBox.scrollTo({ top: this.$refs.scrollableBox.scrollHeight, behavior: 'smooth' });
      this.$store.commit('activateOption',  {kind: 'badges', activated: true});
    },
    hoverEnter(val, scaleVal){
      if (this.shouldHandleHover()) {
        let products = document.querySelectorAll(".cdproduct__" + val + " .cdproduct");
        let listEntry = document.querySelector(".cdlist-entry__" + val);
        for (let i=0; i < products.length; i++){
          products[i].style.transform = 'scale(' + String(scaleVal) +')';
        }
        listEntry.style.boxShadow = "0px 8px 24px 0px #00000033";
      }
    },
    hoverOut(val){
      if (this.shouldHandleHover()) {
        let products = document.querySelectorAll(".cdproduct__" + val + " .cdproduct");
        let listEntry = document.querySelector(".cdlist-entry__" + val);
        for (let i=0; i < products.length; i++){
          products[i].style.transform = 'scale(1)';
        }
        listEntry.style.boxShadow = "none"
      }
    },
    shouldHandleHover() {
      return matchMedia('(pointer:fine)').matches && this.rankingVisible && this.$store.state.internetConnection === true;
    },
    click(val){
      if(this.rankingVisible && this.$store.state.internetConnection === true) {
        let productLink = `product-links.${val}`;
        window.open(this.$t(productLink), "_blank");
      }
    },
    storageAvailable(type) {
      let storage;
      try {
        storage = window[type];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return (
          e instanceof DOMException &&
          // everything except Firefox
          (e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === "QuotaExceededError" ||
            // Firefox
            e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage &&
          storage.length !== 0
        );
      }
    },
    setLanguage() {
      const locale = localStorage.getItem("locale");
      this.$i18n.locale = locale;
    },
    populateStorage(value) {
      localStorage.setItem("locale", value);
    }
  }

}
</script>

<style lang="scss">

 /* online/offline features */
.hover-effects {
    .cdchampinion, .cdproduct {
      cursor: pointer;
    }
  }


:root{
  /* variables */
  --cd-text-color-primary: #303030;
  --cd-text-color-secondary: #7C7C7C;
  --cd-text-color-light: white;
  --cd-color-danfoss-red: #E2000F;
  --cd-color-danfoss-deep-red: #B6000F;
  --cd-color-green: #1AC632;
  --cd-color-inactive: #BFBEBE;
  --cd-background-light-grey: #FAFAFA;
  --cd-background-middle-grey: #E6E6E6;
  --max-width: 1360px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Myriad-Pro, Helvetica, Arial, sans-serif;

  font-size: 1rem;
  color: var(--cd-text-color-primary); 
}


html, body {
  overflow-x: hidden;
}



*{
  font-family: Myriad-Pro, Helvetica, Arial, sans-serif;
}

.app-parent{
  
  min-height: calc(100vh - 76px);
  margin-top: 76px;
  display: flex;
}

#app *{
  box-sizing: initial;
  font-family: Myriad-Pro, Helvetica, Arial, sans-serif;
  line-height: 1.2;
}

#app{
  padding-bottom: 2rem;
}

b {
  font-family: Myriad-Pro-Bold!important;
}

.cd-tooltip__container b {
  font-family: Myriad-Pro-Semi!important;
}


#app span{
  line-height: 1;
}

#app p {
  line-height: 1.5;
  font-weight: 500;
}

.main-content{
  width: 100%;
  //position: absolute;
}

.cdgame{
  display: grid;
  position: relative;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: fit-content(0);
  /* padding-top: 2rem;
  padding-bottom: 1rem; */
}

.cdgame{
  .cdgame__logo-container{
    position: fixed;
    right: 0;
    top: 0;

    > img {
      max-height: 60px;
      padding: 20px;
    }
  }

  .cdgame__popup-overlay{
  position: fixed;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  background: #3030304D;
  z-index: 10;
  display: flex;
  pointer-events: all;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out
  }


.cdgame__popup{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cdgame__popup > div{
  padding: 2.5rem;
  background-color: var(--cd-background-light-grey);
  box-shadow: 0px 8px 24px 0px #00000033;
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 570px;
}

.cdgame__popup .cdgame__button{
  align-self: end;
}


.cdgame__popup  h2{
  margin: 0;
}

.cdgame__popup p{
  margin: 0;
  margin-bottom: 1.5rem;
}

.cdgame__popup-close{
  align-self: flex-end;
  line-height: 0.8;
  position: absolute;
  transform: translate(100%, -100%);
  font-size: 1.25rem;
  cursor: pointer;
}

.fade-in-enter-active, .fade-in-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}

.cdgame__menu{
  position: relative;
  max-height: inherit;
  overflow: hidden;
}

#cdgame__scrollable-box{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: auto;
  scrollbar-width: thin;
}

.cdgame__box{
  padding: 1.25rem; 
  position: relative;
}

.cdgame__box--ui p {
  margin: 1rem 0;
}

.cdgame__box--ui{
  border-radius: 0.125rem;
  background-color: var(--cd-background-light-grey);
}

.cdgame__box--ui:not(:first-child){
  margin-top: 1rem;
}

.cdgame__box--visuals{
  height: 100%;
}

.cdgame__h2{
  font-size: 2rem;
  font-family: Myriad-Pro-Bold!important;
  letter-spacing: 0.3px;
  word-spacing: initial;
}

.cdgame__h4, .cdgame__h4 span{
  font-size: 1rem;
  font-family: Myriad-Pro-Bold!important;
  margin: 1rem 0 0.5rem 0;
  word-spacing: initial;
}

.cdgame__h3{
  display: contents;
  margin-top: 0.5rem;
  font-size: 1.125rem;
  font-family: Myriad-Pro-Bold!important;
  word-spacing: initial;
}

.cdgame__percent{
  color: #B3B3B3;
  margin-left: 0.5rem;
}

.cdgame__h4--no-margin{
  margin-top: 0;
}

.cdgame__h5, .cdgame__h5 span{
  font-size: 0.875rem;
  font-family: Myriad-Pro-Bold!important;
  word-spacing: initial;
  margin: 0;
}

p{
  line-height: 1.5;
}

.cdgame__link{
  color: var(--cd-color-danfoss-red);
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
}

.cdgame__link:hover {
    text-decoration: none;
}

.cdgame__button{
  font-family: Myriad-Pro-Semi!important;
  background: var(--cd-color-danfoss-red);
  color: var(--cd-text-color-light);
  padding: 0.5rem;

  border: none;
	border-radius: 2px;

	cursor: pointer;
	outline: inherit;

  display: flex;
  padding: 0.625rem 1.5rem;

  transition: background-color 0.3s ease-in-out;
}

.cdgame__button--center{
  margin: 0 auto;
}

.cdgame__button--inactive {
  background: var(--cd-color-inactive);
  pointer-events: none;
}

.cdgame__progress-bars{
  position: absolute;
  width: -webkit-fill-available;
  width: -moz-available;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
}

.cdgame__room{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  padding: 1rem 0;
  max-height: 70vh;
  margin: auto;
}

.cdgame__room > svg{
  width: calc(var(--max-width)/2);
  max-width: 100%;
  height: auto;
  margin: auto;
}

.cdgame__regenerative-energy--solar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0px;
  pointer-events: none;
}

.cdgame__regenerative-energy--wind {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0px;
  justify-content: flex-end;
  pointer-events: none;
}

.cdgame__solar-panel-svg, .cdgame__wind-turbine-svg {
  position: relative;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  /* transform: translate(0, -50%); */
}

.cdgame__solar-panel-svg > svg {
  position: absolute;
  width: 5rem;
  height: 5rem;
}

.cdgame__wind-turbine-svg > svg {
  position: absolute;
  width: 7rem;
  height: 7rem;
}

.cdgame__solar-panel-svg > svg:nth-child(1){
  transform: translate(-50%, -185%);
} 

.cdgame__solar-panel-svg > svg:nth-child(2){
  transform: translate(0%, -100%);
} 

.cdgame__solar-panel-svg > svg:nth-child(3){
  transform: translate(100%, -100%);
}

.cdgame__wind-turbine-svg > svg:nth-child(1){
  transform: translate(-100%, -90%);
}

.cdgame__wind-turbine-svg > svg:nth-child(2){
  transform: translate(-20%, -130%);
}

.cdgame__wind-turbine-svg > svg:nth-child(3) {
  transform: translate(60%, -120%);
} 

.cdgame__tooltip{
  display: flex;
  flex-direction: row;
}

.cdvisible{
  opacity: 100;
  transition: all 0.3s ease-in-out;
}

.cdinvisible{
  opacity: 0;
  transition: all 0.3s ease-in-out;
}


/* Champinion Hover Effekt  */

#cdchampinion__pump:hover {
  box-shadow: 0px 8px 24px 0px #00000033;
}

#cdchampinion__pump:hover .cdproduct__pump{
  box-shadow: 0px 8px 24px 0px #00000033;
  scale: 2;
}

#cdgame__button--feedback .cdinvisible{
    transform: scale(0);
  }

#cdgame__button--feedback .cdvisible{
    transform: scale(1);
  }

}


.cdgame__qr-code-container{
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  & img {
    max-width: 200px;
  }
}

@media screen and (min-width: 1201px){
  .cdgame__start-screen-overlay h1 {
    font-size: 102px!important;
  }
}

@media screen and (max-width: 1200px) {

  .cdgame{
    grid-template-columns: 1.5fr 1fr;

    .cdgame__room {
      padding-top: 2rem;
    }


  .cdgame__room {
      padding: 2rem 0;
      width: 90%;
      margin: auto;
      margin-bottom: 2rem;
    }
  .cdgame__h5 {
    width: min-content;
  }
  .cdgame__solar-panel-svg > svg {
    position: absolute;
    width: 7vw;
    height: 7vw;
  }

  .cdgame__wind-turbine-svg > svg {
    position: absolute;
    width: 9vw;
    height: 9vw;
  }

  .cdgame__solar-panel-svg, .cdgame__wind-turbine-svg {
    width: 20%;
  }

  }
}

@media screen and (max-width:768px) {


  #cdgame__button--feedback{
    display: flex;
    width: calc(100% - 3rem);
    justify-content: center;
    margin-top: 2rem;
  }

  .cdgame{
    grid-template-columns: 1.2fr 1fr;

    .cdgame__room {
      padding-top: 4rem;
    }

  }

  
  #game-header .cdgame__header-logo, #start-header .cdgame__header-logo{
    height: 2.5rem;
  }

  #game-header, #start-header{
    padding: 0.25rem;

    .col.d-flex.justify-content-end{
      padding-right: 0;
    }
  }

  .cdgame{
    display: flex;
    flex-direction: column;

  .cdgame__popup{
  padding: 1rem;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cdgame__popup > div{
  max-height: calc(85% - 2*1rem - 2*1.5rem);
  overflow: scroll;
  display: flex;
  margin: 1rem;
  padding: 1.5rem;

  & > h2 {
    margin-top: 0.5rem;
  }
}

.cdgame__popup-close{
  transform: translate(50%,-50%);
}

  .cdgame__h2 {
    font-size: 1.5rem;
  }

  .cdgame__room {
      padding-top: 4.5rem;
      padding-bottom: 2rem;
      margin-bottom: 0;
      width: 100%;
    }

  #cdgame__button--feedback.cdinvisible {
      margin-bottom: -4.5rem;
  }

  #cdgame__button--feedback.cdvisible {
      margin-bottom: 0rem;
  }

  #cdgame__scrollable-box {
    overflow-y: initial;
    overflow-x: initial;
  }
  #cdgame__button--feedback{
    margin-top: 2rem;
  }

  .cdgame > .cdgame__box{
    padding-bottom: 0;
  }

  .cdgame__solar-panel-svg > svg {
    position: absolute;
    width: 12vw;
    height: 12vw;
  }

  .cdgame__wind-turbine-svg > svg {
    position: absolute;
    width: 16vw;
    height: 16vw;
  }

  }

}




</style>
