import Vue from 'vue'
import store from './store'
import IdleVue from 'idle-vue'
import i18n from "./i18n"
import './assets/sass/style.scss'
import VueMobileDetection from "vue-mobile-detection"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'


import App from './App.vue'



Vue.config.productionTip = false

//i18n.locale = 'de'

const eventsHub = new Vue()

Vue.use(IdleVue, { eventEmitter: eventsHub, store, idleTime: 120000 });

Vue.use(VueMobileDetection);

new Vue({
    i18n,
    store,
    IdleVue,
    render: h => h(App)
  }).$mount('#wp-vue-app')