<template>
   <transition name="fade-in-start" appear @enter="showContent = true">
    <div v-if="show" class="cdgame__start-screen-overlay" ref="startScreen">
      <!-- <div class="backdrop"></div> -->
      <transition name="show" @after-leave="show = false">
        <nav v-if="showContent" id="start-header" class="fixed-top navbar justify-content-center">
          <div class="row container-lg">
              <div class="col">
                  <img class="cdgame__header-logo" src="../.././assets/logo/Danfoss-Logo.png" alt="Danfoss">
              </div>
              <div class="col d-none d-md-flex justify-content-center">
              </div>
              <div class="col d-flex justify-content-end">
                  <div class="dropdown">
                      <button class="btn language-switcher dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                          <svg fill="#ffffff" width="20" height="20" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="12"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.048"></g><g id="SVGRepo_iconCarrier"> <path d="M128,28.00037a100,100,0,1,0,100,100A100.113,100.113,0,0,0,128,28.00037Zm91.90771,96H171.94043c-.93994-34.249-13.937-65.64722-36.18994-87.67041A92.1307,92.1307,0,0,1,219.90771,124.00037ZM128,216.03064c-21.71777-20.37207-34.93213-51.28711-35.93164-84.03027h71.86328C162.93213,164.74353,149.71777,195.65857,128,216.03064ZM92.06836,124.00037C93.06787,91.25476,106.27979,60.34143,128,39.97009c21.72021,20.37134,34.93213,51.28467,35.93164,84.03028ZM120.24951,36.33c-22.25293,22.02319-35.25,53.42138-36.18994,87.67041H36.09229A92.1307,92.1307,0,0,1,120.24951,36.33ZM36.09229,132.00037H84.05957c.93994,34.24756,13.937,65.64648,36.18994,87.67041A92.13091,92.13091,0,0,1,36.09229,132.00037Zm99.6582,87.67041c22.25293-22.02393,35.25-53.42285,36.18994-87.67041h47.96728A92.13091,92.13091,0,0,1,135.75049,219.67078Z"></path> </g></svg>
                          <span>{{ $t("current-language")}}</span>
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <span class="dropdown-item" @click="switchLanguage('de')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 5 3">
                                    <rect id="black_stripe" width="5" height="1" y="0" x="0" fill="#000"/>
                                    <rect id="red_stripe" width="5" height="1" y="1" x="0" fill="#D00"/>
                                    <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
                                </svg>
                            <span>Deutsch</span>
                            </span>
                        </li>
                        <li>
                            <span class="dropdown-item" @click="switchLanguage('en')">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="18" height="18" style="transform: scaleY(1.2);">
                                    <clipPath id="s">
                                        <path d="M0,0 v30 h60 v-30 z"/>
                                    </clipPath>
                                    <clipPath id="t">
                                        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
                                    </clipPath>
                                    <g clip-path="url(#s)">
                                        <path d="M0,0 v30 h60 v-30 z" fill="#012169"/>
                                        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
                                        <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
                                        <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
                                        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" stroke-width="6"/>
                                    </g>
                                </svg>
                            <span>English</span>
                            </span>
                        </li>
                    </ul>
                  </div>
              </div>
          </div>
      </nav>
      </transition>
        <div class="container-lg">

        <transition name="fade-up-in">
        <i18n v-if="showContent" path="start-screen.heading.text" tag="h1" class="cdgame__text">
          <template v-slot:0><br><b>{{ $t("start-screen.heading.0") }}</b></template>
        </i18n>
        </transition>
        <transition name="fade-up-in">
        <div v-if="showContent">
          <button class="cdgame__button"
            @click="showContent = false"
          >  
            {{ $t("start-screen.button") }}
          </button>
        </div>
        </transition>
        </div>
    </div>
  </transition>
</template>

<script>
  export default({
      name: "StartScreenOverlay",
      props:{
      },
      data() {
          return {
            show: true,
            showContent: false
          }
      },
      methods: {
        switchLanguage(lang){
                this.$i18n.locale = lang;
        }, 
          hideStartScreen(){
            //this.$refs.startScreen.classList.value = this.$refs.startScreen.classList.value + " cdgame__start-screen-overlay__fade"
          }
      },
      computed: {
          location: () => location,
      },
      mounted(){
        if (this.$isMobile()){
          document.querySelector('meta[name="theme-color"]')?.setAttribute('content', "#869098");
        }
      },
      watch: {
        show(){
          if (this.$isMobile()){
          document.querySelector('meta[name="theme-color"]')?.setAttribute('content', "#E2000F");
          }
        }
         
      }
  })
</script>

<style scoped lang="scss">

.backdrop{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
}

#start-header{
  background-color: transparent;
  color: var(--cd-text-color-light);
  z-index: 10;

  // animation-name: enter-typo;
  // animation-duration: 2s;
  // animation-iteration-count: 1;

  > div {
      padding: 0;
  }

  button > span, li span {
      font-size: 14px;
  }

  button > span {
      display: contents;
  }
}

.btn{
  color: var(--cd-text-color-light);
  font-family: 'Myriad Pro', 'Myriad', Helvetica, Arial, sans-serif;
  display: grid;
  grid-auto-flow: column;
  gap: 0.325rem;
  align-items: center;
}

.cdgame__header-logo{
  height: 60px;
  line-height: 24px;
  margin-left: -1px;
  //transition-duration: .2s;
  transition-property: height;
  transition-timing-function: ease-in-out;
  visibility: hidden;
}

span.dropdown-item{
  cursor: pointer;
  &:active{
      background: var(--cd-background-middle-grey) !important;
      color: unset;
  }

  &:hover{
      background: var(--cd-background-light-grey) !important;
  }
}


.dropdown-menu {
    min-width: 0;
    width: fit-content;
}



.dropdown-item{
  display: grid;
  grid-auto-flow: column;
  gap: 0.325rem;
  align-items: center;
  width: unset;
}

.cdgame__start-screen-overlay{
    position: fixed;
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    z-index: 1000;
    max-width: 100vw;
    animation-name: enter;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    background: #869098;

    > div {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      justify-content: space-evenly;
      align-items: center;
      height: -webkit-fill-available;
      animation-name: enter-typo;
      animation-duration: 1.5s;
      animation-iteration-count: 1;
    }
    h1 {
      color: white;
      font-size: 6vw;
      font-weight: 400;
      text-align: center;
    }
  .cdgame__button {
    font-size: 1.25rem!important;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease-in out;
    animation-name: button;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .cdgame__button:hover {
    background: var(--cd-color-danfoss-deep-red);
    font-size: 1.25rem!important;
    padding: 1rem 2rem;
  }

    @keyframes button {
    0%   {scale: 1;}
    50%  {scale: 1.1;}
    100% {scale: 1;}
  }

  }


  .fade-in-start-enter-active {
  transition: opacity 1.3s ease-in-out, background-color 1s ease-in-out;
  background-color: #869098;
}

.fade-in-start-leave-active {
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.fade-in-start-enter{
  background-color: white;
}

.fade-in-start-leave-to {
  background-color: white;
  opacity: 0;
}

.fade-up-in-enter-active {
  transition: opacity 1s ease-in, transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 0.6s;
  transform: translateY(0);
}

.fade-up-in-leave-active {
  transition: opacity 0.3s ease;
  transition-delay: 0s;
}

.fade-up-in-enter {
  opacity: 0;
  transform: translateY(1rem);
}

.fade-up-in-leave-to {
  opacity: 0;

}

.show-enter-active {
  transition: opacity 0.3s ease-in;
  opacity: 1;
  transition-delay: 0.8s;
}

.show-leave-active {
  transition: opacity 0.2s ease-out;
  opacity: 1;
  transition-delay: 0s;
}

.show-enter, .show-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .cdgame__button{
    font-size: 1rem;
  }
}

@media screen and (max-width:768px) {
  .cdgame__start-screen-overlay h1{
    font-size: 10vw;
  } 
}

</style>