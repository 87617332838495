<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" fill="white"/>
        <path d="M9.45614 20.4475L8.74429 20.0366V25.4557V26.2122L9.45614 26.6232V20.4475Z" fill="#686868"/>
        <path d="M12.7476 21.1179L12.0357 21.5289V23.5554V24.3119L12.7476 24.7229V21.1179Z" fill="#686868"/>
        <path d="M9.43379 4.6303L9.39384 4.65337L8.74429 4.27837V6.50027V6.68595V6.80609L9.45512 7.2165L9.45614 7.2159V4.61742L9.43379 4.6303Z" fill="#6E6E6E"/>
        <path d="M12.0357 4.59995V4.78563V5.18567V5.72659L12.7476 5.31563V2.71711L12.0357 3.12811V4.59995Z" fill="#6E6E6E"/>
        <path d="M12.7476 5.9013L12.0357 6.3123V8.76556L12.7476 8.35456V5.9013Z" fill="#6E6E6E"/>
        <path d="M12.0357 13.3862V13.5719V13.9719V14.3933L12.7476 13.9823V9.11524L12.0357 9.52624V13.3862Z" fill="#6E6E6E"/>
        <path d="M7.66704 26.0777V27.003L15.6806 31.6296V30.7043L7.66704 26.0777Z" fill="#686868"/>
        <path d="M8.74429 25.4557L7.66704 26.0777L15.6806 30.7043L20.4932 27.9258L13.4371 23.852V24.3248L12.7476 24.7229L12.0357 24.3119V23.5554L10.1457 24.6467V26.2251L9.45614 26.6232L8.74429 26.2122V25.4557Z" fill="#969696"/>
        <path d="M18.2165 16.2964L13.9752 13.8477L13.9733 13.8466L18.2165 16.2964Z" fill="#F0F0F0"/>
        <path d="M21.9468 15.2327L21.9487 15.2316L23.0475 14.5972L22.1035 14.0522L18.2165 16.2964L13.9733 13.8466L13.6142 13.6393L13.6107 13.6372L13.478 13.5606L13.2803 13.6747L13.2779 13.6761L12.7476 13.9823L12.0357 14.3933L10.1457 15.4845L9.8759 15.6402L9.61593 15.7903L9.43379 15.8955L9.33799 15.9508L8.72725 16.3034L6 17.878L11.6825 21.1587L21.9468 15.2327Z" fill="#E8E8E8"/>
        <path d="M13.7122 1.53845L12.7796 1L8.46117 3.49317L9.39384 4.03167L13.7122 1.53845Z" fill="#969696"/>
        <path d="M8.46117 4.11492L8.74429 4.27837L9.39384 4.65337V4.03167L8.46117 3.49317V4.11492Z" fill="#6E6E6E"/>
        <path d="M10.1457 20.8456L9.45614 20.4475V26.6232L10.1457 26.2251V24.6467V20.8456Z" fill="#484848"/>
        <path d="M13.4371 20.7198L12.7476 21.1179V24.7229L13.4371 24.3248V23.852V20.7198Z" fill="#484848"/>
        <path d="M11.6825 21.7329V21.1587L6 17.878V18.4521L8.74429 20.0366L9.45614 20.4475L10.1457 20.8456L11.6825 21.7329Z" fill="#F0F0F0"/>
        <path d="M21.9487 15.2316L21.9468 15.2327L11.6825 21.1587V21.7329L12.0357 21.5289L12.7476 21.1179L13.4371 20.7198L15.0004 19.8173L15.0392 19.7949L15.5678 19.4897L18.9335 17.5466L18.9723 17.5241L19.5009 17.219L23.0475 15.1714V14.5972L21.9487 15.2316Z" fill="#DDDDDD"/>
        <path d="M15.6806 31.6296L20.4932 28.8511V27.9258L15.6806 30.7043V31.6296Z" fill="#484848"/>
        <path d="M10.1457 5.87686V5.69119V4.21934L9.45614 4.61742V7.2159L10.1457 6.81782V6.2769V5.87686Z" fill="#484848"/>
        <path d="M9.39384 4.65337L9.43379 4.6303L9.45614 4.61742L10.1457 4.21934L12.0357 3.12811L12.7476 2.71711L13.4371 2.31902L13.7122 2.16021V1.53845L9.39384 4.03167V4.65337Z" fill="#484848"/>
        <path d="M12.7476 5.31563L12.7521 5.31299L13.4371 4.91754V4.90271V4.58181V2.31902L12.7476 2.71711V5.31563Z" fill="#484848"/>
        <path d="M12.7521 5.89866L12.7476 5.9013V8.35456L12.7521 8.35192L13.4371 7.95648V5.50322L12.7521 5.89866Z" fill="#484848"/>
        <path d="M9.61593 7.70935V10.1626L10.1457 9.85675V7.40353L9.61593 7.70935Z" fill="#484848"/>
        <path d="M9.8759 15.6402L10.1457 15.4845V15.0631V14.6631V14.4774V10.6175L9.6246 10.9183L9.61593 10.9133V15.7903L9.8759 15.6402Z" fill="#484848"/>
        <path d="M13.2779 13.6761L13.2803 13.6747L13.478 13.5606L13.4371 13.537L13.4084 13.5204V13.1503L13.4371 13.1337V8.71716L12.7521 9.1126L12.7476 9.11524V13.9823L13.2779 13.6761Z" fill="#484848"/>
        <path d="M10.1457 14.6631V15.0631L11.0907 14.5175L12.0357 13.9719V13.5719L11.0907 14.1175L10.1457 14.6631Z" fill="#DDDDDD"/>
        <path d="M10.1457 14.4774V14.6631L11.0907 14.1175L12.0357 13.5719V13.3862L10.1457 14.4774Z" fill="#F0F0F0"/>
        <path d="M8.72725 16.3034L9.33799 15.9508V7.46979L8.2015 6.81365V15.9999L8.72725 16.3034Z" fill="#F0F0F0"/>
        <path d="M9.61593 10.3052V10.1626V7.70935L10.1457 7.40353L12.0357 6.3123L12.7476 5.9013L12.7521 5.89866L13.4371 5.50322L13.6107 5.40296V13.0335L13.8886 12.873V4.84249L13.4499 5.09578L13.4371 5.10322L9.33799 7.46979V15.9508L9.43379 15.8955L9.61593 15.7903V10.9133V10.3052Z" fill="#DDDDDD"/>
        <path d="M13.4371 5.10322L13.4499 5.09578L13.8886 4.84249L13.4371 4.58181V4.90271L13.4499 4.91011L13.4371 4.91754L12.7521 5.31299L12.7476 5.31563L12.0357 5.72659L10.1457 6.81782L9.45614 7.2159L9.45512 7.2165L8.74429 6.80609L8.64021 6.746L8.74429 6.68595V6.50027L8.2015 6.81365L9.33799 7.46979L13.4371 5.10322Z" fill="#F0F0F0"/>
        <path d="M10.1457 5.87686V6.2769L12.0357 5.18567V4.78563L10.1457 5.87686Z" fill="#DDDDDD"/>
        <path d="M10.1457 5.69119V5.87686L12.0357 4.78563V4.59995L11.0907 5.14557L10.6182 5.41838L10.1457 5.69119Z" fill="#F0F0F0"/>
        <path d="M13.6107 5.40296L13.4371 5.50322V7.95648L13.451 7.94845L13.5832 8.02477V8.63284L13.4371 8.71716V13.1337L13.6107 13.0335V5.40296Z" fill="#F0F0F0"/>
        <path d="M13.8886 12.873L13.6107 13.0335L13.4371 13.1337L13.4084 13.1503L18.2165 15.9262L26.2301 11.2997L21.4219 8.5237L13.8886 12.873Z" fill="#F0F0F0"/>
        <path d="M13.4084 13.5204L13.4371 13.537L13.478 13.5606L13.6107 13.6372L13.6142 13.6393L13.9733 13.8466L13.9752 13.8477L18.2165 16.2964V15.9262L13.4084 13.1503V13.5204Z" fill="#EBEBEB"/>
        <path d="M26.2301 11.6698V11.2997L18.2165 15.9262V16.2964L22.1035 14.0522L26.2301 11.6698Z" fill="#D1D1D1"/>
        <path d="M12.7476 9.11524L12.7521 9.1126L13.4371 8.71716L13.5832 8.63284V8.02477L11.6039 9.16751L9.6246 10.3103V10.9183L10.1457 10.6175L12.0357 9.52624L12.7476 9.11524Z" fill="#DDDDDD"/>
        <path d="M9.61593 10.1626V10.3052L9.6246 10.3103L11.6039 9.16751L13.5832 8.02477L13.451 7.94845L13.4371 7.95648L12.7521 8.35192L12.7476 8.35456L12.0357 8.76556L10.1457 9.85675L9.61593 10.1626Z" fill="#F0F0F0"/>
        <path d="M9.6246 10.3103L9.61593 10.3052V10.9133L9.6246 10.9183V10.3103Z" fill="#969696"/>
        <path d="M8.64021 6.746L8.74429 6.80609V6.68595L8.64021 6.746Z" fill="#DDDDDD"/>
        <g clip-path="url(#clip0_430_19377)">
        <path d="M18.9409 14.1317L25.3659 10.4223L20.673 7.71289L14.248 11.4223L18.9409 14.1317Z" fill="#969696"/>
        <path d="M25.3659 10.4223L18.9409 14.1317V15.3556L19.6548 14.9434V14.0879L21.1261 13.2385L21.7965 12.8515V13.7069L22.5104 13.2948V12.4393L23.9816 11.5899L24.652 11.2029V12.0583L25.3659 11.6461V10.4223Z" fill="#484848"/>
        <path d="M23.9816 11.5899V11.6712L24.652 12.0583V11.2029L23.9816 11.5899Z" fill="#686868"/>
        <path d="M21.1261 13.2385V13.3199L21.7965 13.7069V12.8515L21.1261 13.2385Z" fill="#686868"/>
        <path d="M18.9409 14.1317L14.248 11.4223V12.6462L14.9185 13.0332V12.1778L15.6324 12.5899L16.2593 12.9519V13.8073L16.9297 14.1944V13.3389L17.6436 13.7511L18.2705 14.1131V14.9685L18.9409 15.3556V14.1317Z" fill="#686868"/>
        <path d="M17.6436 13.7822V13.7511L16.9297 13.3389V14.1944L17.6436 13.7822Z" fill="#484848"/>
        <path d="M15.6324 12.6211V12.5899L14.9185 12.1778V13.0332L15.6324 12.6211Z" fill="#484848"/>
        <g clip-path="url(#clip1_430_19377)">
        <path d="M21.7553 10.7308L23.1425 9.92995V8.32821L21.7553 9.12907V10.7308Z" fill="#DDDDDD"/>
        <path d="M20.3682 8.32821V9.92995L21.7553 10.7308V9.12907L20.3682 8.32821Z" fill="#F7F7F7"/>
        <path d="M23.1425 8.32821L21.7553 7.52734L20.3682 8.32821L21.7553 9.12907L23.1425 8.32821Z" fill="#F0F0F0"/>
        </g>
        <g clip-path="url(#clip2_430_19377)">
        <path d="M19.853 11.8851L21.2401 11.0842V9.4825L19.853 10.2834V11.8851Z" fill="#DDDDDD"/>
        <path d="M18.4658 9.4825V11.0843L19.853 11.8851V10.2834L18.4658 9.4825Z" fill="#F7F7F7"/>
        <path d="M21.2401 9.4825L19.853 8.68164L18.4658 9.4825L19.853 10.2834L21.2401 9.4825Z" fill="#F0F0F0"/>
        </g>
        <g clip-path="url(#clip3_430_19377)">
        <path d="M17.9516 12.9955L19.3387 12.1946V10.5929L17.9516 11.3937V12.9955Z" fill="#DDDDDD"/>
        <path d="M16.5645 10.5929V12.1946L17.9516 12.9955V11.3937L16.5645 10.5929Z" fill="#F7F7F7"/>
        <path d="M19.3387 10.5929L17.9516 9.79199L16.5645 10.5929L17.9516 11.3937L19.3387 10.5929Z" fill="#F0F0F0"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_430_19377">
        <rect width="11.8721" height="11.8721" fill="white" transform="translate(13.8574 3.73926)"/>
        </clipPath>
        <clipPath id="clip1_430_19377">
        <rect width="3.81445" height="3.81445" fill="white" transform="translate(19.8486 7.22363)"/>
        </clipPath>
        <clipPath id="clip2_430_19377">
        <rect width="3.81445" height="3.81445" fill="white" transform="translate(17.9463 8.37793)"/>
        </clipPath>
        <clipPath id="clip3_430_19377">
        <rect width="3.81445" height="3.81445" fill="white" transform="translate(16.0449 9.48828)"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'LiftSVG'
    }
</script>