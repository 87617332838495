<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_403_9377)">
        <path d="M12.8753 2.37951L11.963 2.3468C11.8652 2.34331 11.7671 2.3395 11.6683 2.3395C6.33722 2.3395 2 6.67672 2 12.0078C2 14.2595 2.77511 16.4117 4.19544 18.1388L5.5872 17.3355C4.30182 15.8697 3.58196 13.9829 3.58196 12.0078C3.58196 7.549 7.20949 3.92147 11.6683 3.92147C11.803 3.92147 11.9367 3.92623 12.07 3.93321L12.8756 3.97513V6.37919L18.4782 3.14477L12.8753 -0.0899658V2.37951Z"/>
        <path d="M5.57375 20.6753L11.1764 23.9097V21.6949L11.9975 21.6673C17.2343 21.492 21.3369 17.2488 21.3369 12.0075C21.3369 9.7927 20.5821 7.6668 19.1986 5.95147L17.8103 6.75293C19.057 8.20915 19.7546 10.0702 19.7546 12.0075C19.7546 16.3266 16.3779 19.8742 12.067 20.0837L11.1764 20.1272V17.4406L5.57375 20.6753Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3511 16.4451L11.4193 12.5092L8.84246 12.5127L10.4514 7.75857L12.6475 7.76016L11.6165 10.5862L14.494 10.5907L10.3511 16.4451Z"/>
        </g>
        <defs>
        <clipPath id="clip0_403_9377">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'RegenerativeEnergySVG',
}
</script>
    