<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" fill="white"/>
        <path d="M23.0723 10.699L8.93021 2.53418L7.79883 3.18736L21.941 11.3523L23.0723 10.699Z" fill="#EDEDED"/>
        <path d="M21.941 29.7789L23.0723 29.1258V10.699L21.941 11.3523V29.7789Z" fill="#D9D9D9"/>
        <path d="M9.34114 21.5499L9.18526 21.46V4.80168L8.51444 4.41439V22.0272L9.34114 21.5499Z" fill="#DCDCDC"/>
        <path d="M9.18526 21.46L9.34114 21.5499L15.4078 25.0525V8.39421L9.18526 4.80168V21.46Z" fill="#B6B6B6"/>
        <path d="M15.7209 8.57498L15.4078 8.39421V25.0525L15.7209 24.8717V8.57498Z" fill="#7A7A7A"/>
        <path d="M15.7209 24.8717V24.938L21.2254 28.116V11.753L15.7209 8.57498V24.8717Z" fill="#B6B6B6"/>
        <path d="M7.79883 3.18736L7.79883 21.6141L8.51444 22.0272V4.41439L9.18526 4.80168L15.4078 8.39421L15.7209 8.57498L21.2254 11.753V28.116V29.3658L21.941 29.7789V11.3523L7.79883 3.18736Z" fill="#E0E0E0"/>
        <path d="M18.9229 6.87935L17.8825 4.31055L17.4516 4.55926L18.4921 7.12809L18.9229 6.87935Z" fill="#767676"/>
        <path d="M17.4516 4.55926L16.4111 5.92661L18.4921 7.12809L17.4516 4.55926Z" fill="#606060"/>
        <path d="M12.7041 1.81905L13.7446 4.38785L14.7851 3.02051L12.7041 1.81905Z" fill="#606060"/>
        <path d="M15.2159 2.77176L13.1349 1.57031L12.7041 1.81905L14.7851 3.02051L15.2159 2.77176Z" fill="#767676"/>
        <path d="M14.1754 4.13911L15.2159 2.77176L14.7851 3.02051L13.7446 4.38785L14.1754 4.13911Z" fill="#4B4B4B"/>
    </svg>
</template>

<script>
    export default {
        name: 'ElevatorSVG'
    }
</script>