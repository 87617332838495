<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 3.66659H7.33333V4.99992H6V3.66659ZM6 6.33325H7.33333V10.3333H6V6.33325ZM6.66667 0.333252C2.98667 0.333252 0 3.31992 0 6.99992C0 10.6799 2.98667 13.6666 6.66667 13.6666C10.3467 13.6666 13.3333 10.6799 13.3333 6.99992C13.3333 3.31992 10.3467 0.333252 6.66667 0.333252ZM6.66667 12.3333C3.72667 12.3333 1.33333 9.93992 1.33333 6.99992C1.33333 4.05992 3.72667 1.66659 6.66667 1.66659C9.60667 1.66659 12 4.05992 12 6.99992C12 9.93992 9.60667 12.3333 6.66667 12.3333Z" fill="#7C7C7C"/>
    </svg>
</template>

<script>
    
    export default ({
        name: 'TooltipSVG',
    })
</script>