<template>
    <div class="cdtabs" :class="{ 'cdtabs--inactive' : !inputPossible }">
        <label v-for="(value, index) in values" class="cdtabs__tab" :key="value" ref="tabs">
            <input @input="OnTabInput" ref="defaultOpt" v-if="index === defaultOpt" type="radio" :name="name" class="cdtabs__tab-input" checked="checked" :disabled=!inputPossible>

            <input @input="OnTabInput" v-else type="radio" :name="name" class="cdtabs__tab-input" :disabled=!inputPossible>
            <div class="cdtabs__tab-box">{{ value }}</div>
        </label>
    </div>
  </template>
  
<script>
  export default {
    name: 'TabInput',
    data() {
        return {
          tabVal: null
        }
    },
    props: {
        values: Array,
        name: String,
        defaultOpt: {
          default: 0,
          type: Number
        },
        isActive: Boolean,
        inputPossible: {
          default: true,
          type: Boolean
        },
    },
    methods: {
      OnTabInput(event) {
        this.tabVal = this.values.indexOf(event.target.nextElementSibling.innerHTML.trim());
        this.$emit('updateValue');
      },
      ResetToDefaultValue(){
        this.$refs.defaultOpt[0].checked = true;
        this.tabVal = this.defaultOpt;
      }

    } ,
    watch: {
      inputPossible(newVal){
        if(!newVal) {
          this.ResetToDefaultValue();
          this.$emit('updateValue');
        }
      },
      isActive(newVal) {
        if (!newVal) {
          this.ResetToDefaultValue();
          this.$emit('updateValue');
        }
      },
      '$i18n.locale': function(newVal, oldVal) {
              setTimeout(()=> {
                if (this.tabVal !== null){
                  this.$refs.tabs[this.tabVal].getElementsByTagName('input')[0].checked = true;
                }
          }
        ,10);
        }
      
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .cdgame{
    .cdtabs {
  display: flex;
  border-radius: 0.25rem;
  border: 1px solid var(--cd-color-danfoss-red);
  font-size: 0.875rem;
  font-family: Myriad-Pro-Semi!important;
  height: 100%;
  height: -webkit-fill-available;
  transition: border-color 0.3s ease-in-out;
}



.cdtabs__tab {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cdtabs__tab-input {
  width: 0;
  height: 0;
  margin: 0;
  display: none;
}

.cdtabs__tab-box {
  font-family: Myriad-Pro-Semi!important;
  height: 100%;
  color: var(--cd-color-danfoss-red);
  height: -webkit-fill-available;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}


.cdtabs--inactive > .cdtabs__tab{
  cursor: default;
}

.cdtabs__tab:first-child > .cdtabs__tab-box {
  border-radius: 2px 0 0 2px;
}

.cdtabs__tab:last-child > .cdtabs__tab-box {
  border-radius:  0 3px 3px 0;
}

.cdtabs__tab:nth-child(2) {
    border-right: 1px solid var(--cd-color-danfoss-red);
    border-left: 1px solid var(--cd-color-danfoss-red);
    transition: border-color 0.3s ease-in-out;
}


.cdtabs__tab:last-child {
    border-right: none;
}



.cdtabs__tab-input:checked + .cdtabs__tab-box {
  background: var(--cd-color-danfoss-red);
  color: white;
}

.cdtabs--inactive{
  border-color: var(--cd-color-inactive);
}

.cdtabs--inactive .cdtabs__tab:nth-child(2) {
    border-left: 1px solid var(--cd-color-inactive);
}


.cdtabs--inactive .cdtabs__tab-input:checked + .cdtabs__tab-box {
  background: var(--cd-color-inactive);
  color: white;
}

.cdtabs--inactive .cdtabs__tab-input:not(:checked) + .cdtabs__tab-box {
  background: white;
  color: var(--cd-color-inactive);
}

  }

</style>
  