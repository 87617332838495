<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_430_19357)">
        <rect width="32" height="32" fill="white"/>
        <path d="M13.8873 10.0362L20.7665 6.06445L27.6458 10.0362L20.7665 14.0079L13.8873 10.0362Z" fill="#303030"/>
        <path d="M20.7665 14.008V14.0079L27.6458 10.0362V17.1234V17.9797L20.9771 21.8298V17.7521L20.7665 17.6305C20.7665 16.2158 20.7665 15.4226 20.7665 14.008Z" fill="#484848"/>
        <path d="M29.1289 17.9797V19.0758L20.7665 23.9038V22.8077L29.1289 17.9797Z" fill="#484848"/>
        <path d="M20.9771 22.0671V21.8298L27.6458 17.9797V17.1234L29.1289 17.9797L20.7665 22.8077L20.2304 22.4981L20.9771 22.0671Z" fill="#303030"/>
        <path d="M20.7665 14.0079V14.008C20.7665 15.4226 20.7665 16.2158 20.7665 17.6305L20.3158 17.3703L20.2889 17.0397L20.119 16.2946L19.843 15.5391L19.4715 14.802L19.019 14.1118L18.5026 13.495L17.9423 12.9753L17.3597 12.5727L16.777 12.3025L16.2167 12.1753L15.7004 12.1959L15.2478 12.3635L13.8873 13.149V10.0362L20.7665 14.0079Z" fill="#686868"/>
        <path d="M20.7665 23.9038L19.2811 23.0462L20.2304 22.4981L20.7665 22.8077V23.9038Z" fill="#686868"/>
        <path d="M2.4652 18.7709L2.91975 18.3938L3.47368 18.1887L4.10562 18.1635L4.79131 18.3192L5.5044 18.6498L6.2175 19.1426L6.90319 19.7786L7.53513 20.5336L8.08903 21.3782L8.5436 22.2803L8.88138 23.205L9.08938 24.1168L9.1596 24.9808L9.08938 25.7637L8.88138 26.4354L8.5436 26.9701L8.08903 27.3472L7.53513 27.5523L6.90319 27.5775L6.2175 27.4218L5.5044 27.0912L4.79131 26.5984L4.10559 25.9623L3.47368 25.2074L2.91975 24.3628L2.4652 23.4607L2.12743 22.536L1.9194 21.6242L1.84917 20.7602L1.9194 19.9773L2.12743 19.3056L2.4652 18.7709Z" fill="#686868"/>
        <path d="M14.6963 19.7092L14.6222 19.4682L14.3462 18.7126L13.9747 17.9756L13.5222 17.2854L13.0058 16.6686L12.8123 16.367L12.1267 15.731L11.4136 15.2382L10.7829 14.9458L13.8873 13.149L15.2478 12.3635L15.7004 12.1959L16.2167 12.1753L16.777 12.3025L17.3597 12.5727L17.9423 12.9753L18.5026 13.495L19.019 14.1118L19.4715 14.802L19.843 15.5391L20.119 16.2946L20.2889 17.0397L20.3158 17.3703L18.8835 16.5433L14.6963 18.9608L14.6963 19.7092Z" fill="#484848"/>
        <path d="M10.7004 14.9076L10.7829 14.9458L11.4136 15.2382L12.1267 15.731L12.8123 16.367L13.4443 17.1219L13.9982 17.9666L14.2255 18.4176L14.4528 18.8687L14.7905 19.7934L14.9985 20.7052L15.0688 21.5692L14.9985 22.3521L14.7905 23.0238L14.7555 23.0792L14.4528 23.5584L13.9982 23.9356L8.08903 27.3472L8.5436 26.9701L8.88138 26.4354L9.08938 25.7637L9.1596 24.9808L9.08938 24.1168L8.88138 23.205L8.5436 22.2803L8.08903 21.3782L7.53513 20.5336L6.90319 19.7786L6.2175 19.1426L5.5044 18.6498L4.79131 18.3192L4.10562 18.1635L3.47368 18.1887L2.91975 18.3938L8.82891 14.9822L9.3828 14.7771L10.0147 14.7519L10.7004 14.9076Z" fill="#484848"/>
        <path d="M20.7665 17.6305L20.9771 17.7521L16.7899 20.1695L14.6963 18.9608L18.8835 16.5433L20.3158 17.3703L20.7665 17.6305Z" fill="#303030"/>
        <path d="M20.9771 21.8298V22.0671L20.2304 22.4981L19.2811 23.0462L16.7899 24.4845L16.7899 20.1695L20.9771 17.7521V21.8298Z" fill="#484848"/>
        <path d="M14.7555 23.0792L14.7905 23.0238L14.9985 22.3521L15.0688 21.5692L14.9985 20.7052L14.7905 19.7934L14.6963 19.7092L14.6963 18.9608L16.7899 20.1695L16.7899 24.4845L14.6963 23.2758L14.7555 23.0792Z" fill="#686868"/>
        <path d="M14.6963 19.7092L14.7905 19.7934L14.4528 18.8687L14.2255 18.4176L13.9982 17.9666L13.4443 17.1219L12.8123 16.367L13.0058 16.6686L13.5222 17.2854L13.9747 17.9756L14.3462 18.7126L14.6222 19.4682L14.6963 19.7092Z" fill="#484848"/>
        </g>
        <defs>
        <clipPath id="clip0_430_19357">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'ConveyorSVG'
    }
</script>