<template>
  <transition name="fade-up-in" appear>
    <div
      v-if="shown"
      class="pwa-prompt"
      :class="[{ shown }]"
    >
      <div>
        {{ $t("install-prompt.text") }}
        <button class="install-button" @click="installPWA">
          {{ $t("install-prompt.prompt") }}
        </button>

        <!-- <button class="close-button" @click="dismissPrompt">
          <span aria-hidden="true">×</span>
          <span class="sr">Dismiss without installing</span>
        </button> -->
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  name: "PWAPrompt",
  data(){
    return {
      installEvent: undefined,
      shown: false
    }
  },

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e;
      this.shown = true;
    });
    setTimeout(() => {
        this.shown = false;
      }, "10000");
  },
  methods: {
    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the banner once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
    dismissPrompt() {
      this.shown = false
    }
  }
}
</script>


<style scoped lang="scss">
// If repurposing this component, you'll want to update the CSS variables.
.pwa-prompt {
  z-index: 10000;
  position: fixed;
  font-size: 1rem;
  line-height: 1;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100vw;

  >div{
    background: #313438;
    min-height: 4.5rem;
    padding: 1rem;
    color: var(--cd-background-light-grey);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    line-height: 1.2;
  }
  .install-button {
    font-size: inherit;
    font-family: Myriad-Pro-Semi!important;
    background-color: transparent;
    color: var(--cd-color-danfoss-red);
    margin: 0 0 0 4rem;
    border: 0;
    border-radius: 4px;
    line-height: 1;
    text-transform: uppercase;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: -.25rem;
    font-size: 3rem;
    background: transparent;
    border: 0;
    padding: 0 0.75rem;
    height: 100%;
    line-height: 1;
  }

  // For the accessible text. Most projects have their own .sr-only class or similar in the base styles.
  .sr {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -100vw;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
}

.fade-up-in-enter-active, .fade-up-in-leave-active {
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(0);
}

.fade-up-in-enter, .fade-up-in-leave-to {
  opacity: 0;
  transform: translateY(4rem);
}
</style>