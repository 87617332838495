<template>
    <InputToggle ref="toggler" @toggleInput="isActive = $refs.toggler.isActive" :canBeActivated=inputPossible :shouldPulse=this.shouldPulse>
        <template #knob>
            <GreenEnergySVG />
        </template>
        <template #input="slotProps">
            <SliderInput ref="input" @updateValue="value = $refs.input.sliderVal" :values="['0', '10' , '20', '30']" :defaultOpt="0" :isActive=slotProps.isActive :pulse=sliderPulse />
        </template>
    </InputToggle>
</template>

<script>
    import InputToggle from '.././InputToggle.vue'
    import SliderInput from '.././SliderInput.vue'
    import GreenEnergySVG from '.././svg/icons/GreenEnergySVG.vue'

    export default({
        name: "GreenEnergyToggleInput",
        components: {
            InputToggle,
            SliderInput,
            GreenEnergySVG
        },
        data() {
            return {
            isActive: 0,
            value: null,
            canBeActivated: false,
            shouldPulse: false,
            sliderPulse: true
            }
        },
        computed: {
            inputPossible() {
                return this.$store.state.energySources.photovoltaic.wasActivated;
            },
            photovoltaic() {
                return this.$store.state.energySources.photovoltaic.value;
            },
            photovoltaicActivated() {
                return this.$store.state.energySources.photovoltaic.wasActivated;
            },
            driveSystem(){
                return this.$store.state.driveSystem.isVisible; 
            }
        },
        watch: {
            driveSystem(newVal){
                if (newVal === true && this.$store.state.energySources.greenEnergy.value === 0){
                    this.sliderPulse = false;
                }
            },
            photovoltaic(newVal, oldVal) {
                if (newVal > 0 && this.$store.state.energySources.greenEnergy.activated === false){
                    this.shouldPulse = true;
                }
                else if (newVal >= 0 && oldVal === 0 && this.$store.state.energySources.greenEnergy.wasActivated === true && this.$store.state.energySources.greenEnergy.value === 0){
                    this.sliderPulse = false;
                }
            },
            isActive(newVal) {
                if (newVal === true){
                    this.$store.commit('setActivatedStatus',  { input: 'green-energy', activated: true });
                }
                this.$store.commit('setInputStatus',  { input: 'green-energy', activated: parseInt(newVal) });
            },
            value(newVal){
                this.$store.commit('setInputValue',  { input: 'green-energy', value: parseInt(newVal) });
            }
        }
    })
</script>